import * as React from 'react';
import { translate } from 'react-i18next';
import HtmlFormat from '../html-format/html-format';

interface IContentRowTemplateProps {
    i18n?: any;
    t?: any; // translate
    data: any;
}

class ContentRowTemplate extends React.Component<IContentRowTemplateProps> {
    constructor(props: IContentRowTemplateProps) {
        super(props);
    }

    public render() {
        const { i18n } = this.props;
        return (
            <div id='content-row'>
                <div className='row-container'>
                    <div className='container-fluid'>
                        {this.props.data.map((arr: any[], i: number) => {
                            return (
                                <div className='content-inner row-fluid' key={i}>
                                    {arr.map((item: any, i: number) => {
                                        return <HtmlFormat dataContext={item} key={i} language={i18n.language} />;
                                    })}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    }
}

export default translate('translations')(ContentRowTemplate);
