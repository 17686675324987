import * as React from 'react';
import { Link } from 'gatsby';
import { translate } from 'react-i18next';
interface IFeedBackProps {
    t?: any;
    i18n?: any;
    success: boolean;
    contactName: string;
}

interface IFeedBackState {
    
}

class FeedBackPopupComponent extends React.Component<IFeedBackProps, IFeedBackState> {
    constructor(props: IFeedBackProps) {
        super(props);
        this.state = {

        };
    }
    
    public componentDidMount() {

    }

    public getFeedBackTitle(ContactName: string) {
        switch(ContactName) {
            case 'CONTACT_CONSTRUCTION': return 'CONTACT_CONSTRUCTION_FEEDBACK_TITLE';
            case 'CONTACT_ME' : return 'CONTACT_ME_FEEDBACK_TITLE';
            default: return '';
        }
    }
    public getFeedBackMessage (ContactName: string, success: boolean) {
        switch(ContactName) {
            case 'CONTACT_DEMAND' : return success === true? 'CONTACT_DEMAND_FEEDBACK_SUCCESS' : 'CONTACT_DEMAND_FEEDBACK_ERROR';
            case 'CONTACT_ME' : return success === true? 'CONTACT_ME_FEEDBACK_SUCCESS' : 'CONTACT_ME_FEEDBACK_ERROR';
            case 'DEMAND_REGISTER' : return success === true? 'MAIL_THANK_TEXT' : 'MAIL_ERROR';
            case 'SEND_TO_FRIEND' : return success === true? 'SEND_TO_FRIEND_FEEDBACK_SUCCESS' : 'SEND_TO_FRIEND_FEEDBACK_ERROR';
            case 'SITE_CONTACT': return success === true? 'SITE_CONTACT_FEEDBACK_SUCCESS' : 'SITE_CONTACT_FEEDBACK_ERROR';
            default: return '';
        }
    }

    public getReturnURL() {
        if(typeof window !== 'undefined') {
            // get Url back
            const Url = window.sessionStorage.getItem('URL');
            return !!Url? Url : '/';
        } else {
            return '/';
        }
    }

    public render() {
        const { t } = this.props;
        return (
                <div>
                    {this.props.contactName === 'DEMAND_REGISTER' && <Link to={this.getReturnURL()}>{t('DEMAND_FEEDBACK_RETURN')}</Link>}
                    <div className={this.props.success === true? 'success_title' : 'error_title'}><h3>{t(this.getFeedBackTitle(this.props.contactName.toUpperCase()))}</h3></div>
                    <div className={this.props.success === true? 'success_message': 'error_message'}><h3>{t(this.getFeedBackMessage(this.props.contactName.toUpperCase(),this.props.success))}</h3></div>
                </div>
        );
    }
}

export default translate('translations')(FeedBackPopupComponent);
