import * as React from 'react';
import { translate } from 'react-i18next';
import '../../components/PropertyComponent/css/property-detail.css';
import Slider from '../../components/SliderComponent/property-detail-slider/property-detail-slider';
import PanelRight from '../../components/PropertyComponent/detail/panel-right';
import PanelContent from '../../components/PropertyComponent/detail/panel-content';
import HeaderDetail from '../../components/PropertyComponent/detail/header-detail';
import * as helper from '../../helper/helpers';

interface IPropertyDetailProps {
    dataContext: any;
    i18n: any;
    t: any;
}

class DetailPage extends React.Component<IPropertyDetailProps> {
    public render() {
        const { i18n, dataContext } = this.props;
        helper.setLanguageDefault(i18n.language);
        const propList: any[] = !!dataContext.listDefault ? dataContext.listDefault : [];
        let prop: any = dataContext.property.length > 0 ? dataContext.property.filter((item: any) => item.language === i18n.language) : [];
        prop = prop[0];
        return (
            <div id='PropertyRegion'>
                <div className='row-fluid nav-btns-wrapper hidden-print'>
                    <HeaderDetail prop={prop} propListDefault={propList} />
                </div>
                <div className='row-fluid header'>
                    <div className='span8'>
                        <h3 className='pull-left leftside' style={{ textTransform: 'none' }}>
                            {prop.TypeDescription}
                        </h3>
                    </div>
                    <div className='span4'>
                        <h3 className='pull-right rightside'>
                            {helper.formatCurrency(prop.Price)}
                        </h3>
                    </div>
                </div>
                <div className='row-fluid'>
                    <div className='span8 panel-content'>
                        {!!prop && !!prop.ImageUrls && <Slider ImageList={!!prop.ImageUrls ? prop.ImageUrls : []} widthImg='500' showThumbnails={true} autoPlay={false} />}
                    </div>
                    <div className='span4 panel-left'>
                        <PanelRight prop={prop} />
                    </div>
                </div>
                <div className='row-fluid'>
                    <PanelContent prop={prop} />
                </div>
            </div>
        );
    }
}

export default translate('translations')(DetailPage);
