import * as React from 'react';
import { translate } from 'react-i18next';
import { navigate } from 'gatsby';
import Modal from '../../ModalComponent/modal';
import SendToFriend from '../../FormComponent/send-to-friend';
// import 'font-awesome/css/font-awesome.min.css';
import * as FontAwesomeIcon from 'react-fontawesome';

interface IHeaderDetailProps {
    i18n?: any;
    t?: any;
    prop: any;
    propListDefault: any[];
}

interface IHeaderDetailState {
    propList: any[];
    propIndex: number;
    modalIsOpen: boolean;
}

class HeaderDetailComponent extends React.Component<IHeaderDetailProps, IHeaderDetailState> {
    constructor(props: IHeaderDetailProps) {
        super(props);
        this.state = {
            propList: [],
            propIndex: 0,
            modalIsOpen: false
        };
    }

    public openModalContact() {
        this.setState({ modalIsOpen: true });
    }

    public closeModalContact(value: boolean) {
        this.setState({ modalIsOpen: value });
    }

    public async componentDidMount() {
        const { prop, propListDefault } = this.props;
        if (typeof window !== 'undefined') {
            // prepare propList
            const propListJsonString = window.sessionStorage.getItem(`propList${prop.Goal}`);
            let list = [];
            if (propListJsonString === null) {
                list = propListDefault;
            } else {
                list = JSON.parse(propListJsonString);
            }
            // list = list.filter((item: any) => item.Marquee !== 3 && item.Marquee !== 4);
            // const propFind = list.find((ID: any) =>ID === prop.ID);
            const index = list.indexOf(prop.ID);
            this.setState({ propList: list, propIndex: index });
        }
    }

    public backToLocationBefore() {
        if (typeof window !== 'undefined') {

            // get Url back
            let Url = window.sessionStorage.getItem('URL');
            if (Url === '' || Url === null) {
                Url = '/';
            }

            navigate(Url);
        }
    }

    public nextProperty() {
        if (this.state.propIndex !== (this.state.propList.length - 1)) {
            const nextIndex = this.state.propIndex + 1;
            const propID = this.state.propList[nextIndex];
            this.setState({ propIndex: nextIndex });
            navigate('/property/' + propID);
        }
    }

    public prevProperty() {
        if (this.state.propIndex !== 0) {
            const nextIndex = this.state.propIndex - 1;
            const propID = this.state.propList[nextIndex];
            this.setState({ propIndex: nextIndex });
            navigate('/property/' + propID);
        }
    }

    public render() {
        return (
            <div className='span12'>
                <Modal modalIsOpenProps={this.state.modalIsOpen} closeModal={this.closeModalContact.bind(this)} children={<SendToFriend isPopup={true} />} width='400px' />
                <div className='pull-left nav-btns-left hidden-phone'>
                    <div className='arrow'>
                        <button id='form_send_to_friend' className='icon' onClick={this.openModalContact.bind(this)}>
                            <FontAwesomeIcon name='envelope' />
                        </button>
                    </div>
                    <div className='arrow'>
                        <button id='print_btn' className='icon'>
                            <FontAwesomeIcon name='print' />
                        </button>
                    </div>
                </div>
                <div className='pull-right nav-btns-right'>
                    <div className='arrow'>
                        <button className={`prev icon ${this.state.propIndex !== 0 ? 'icon-active' : 'icon-disabled'}`} onClick={this.prevProperty.bind(this)} >
                            <FontAwesomeIcon name='arrow-circle-left' />
                        </button>
                    </div>
                    <div className='arrow'>
                        <button className='first list icon' onClick={this.backToLocationBefore.bind(this)} >
                            <FontAwesomeIcon name='list' />
                        </button>
                    </div>
                    <div className='arrow'>
                        <button className={`next icon ${this.state.propIndex !== (this.state.propList.length - 1) ? 'icon-active' : 'icon-disabled'}`} onClick={this.nextProperty.bind(this)}>
                            <FontAwesomeIcon name='arrow-circle-right' />
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default translate('translations')(HeaderDetailComponent);
