import * as React from 'react';
import Layout from '../components/GeneralComponent/layout';
import { translate } from 'react-i18next';
import * as helper from '../helper/helpers';
import ShowCaseComponent from './page-child/showcase-tmpl';
import ContentRowComponent from './page-child/content-row-tmpl';
import MainRowComponent from './page-child/main-row-tmpl';
import BottomRowComponent from './page-child/bottom-row-tmpl';

import {sortBy, chunk } from 'lodash';

interface IPageProps {
    i18n?: any;
    t?: any; // translate
    pageContext: any;
}

class PageTemplate extends React.Component<IPageProps> {
  constructor(props: IPageProps) {
    super(props);
  }

  public render() {
    const { i18n } = this.props;
    helper.setLanguageDefault(i18n.language);
    const { pageContext } = this.props;
    let { bottomRow, contentRow, mainRow, showcaseRow } = pageContext.data;
    bottomRow = sortBy(bottomRow,['sort']);
    bottomRow = chunk(bottomRow, bottomRow.length);
    contentRow = sortBy(contentRow,['sort']);
    contentRow = chunk(contentRow, contentRow.length);
    mainRow = sortBy(mainRow,['sort']);
    mainRow = chunk(mainRow, mainRow.length);
    showcaseRow = sortBy(showcaseRow,['sort']);
    return (
      <Layout>

        {showcaseRow.length > 0 && <ShowCaseComponent data={showcaseRow} /> }

        {contentRow.length > 0 &&  <ContentRowComponent data={contentRow} />}

        {mainRow.length > 0 &&  <MainRowComponent data={mainRow} />}

        {bottomRow.length > 0 && <BottomRowComponent data={bottomRow} />}

      </Layout>
    );
  }
}

export default translate('translations')(PageTemplate);
