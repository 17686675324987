import * as React from 'react';
import { translate } from 'react-i18next';
import * as FontAwesomeIcon from 'react-fontawesome';
import Modal from '../../ModalComponent/modal';
import { isUndefined, isNumber } from 'lodash';
import { navigate } from 'gatsby';
import * as helper from '../../../helper/helpers';

interface IFeatureButtonProps {
    i18n?: any;
    t?: any;
    propsList: any[];
}

interface IFeatureButtonState {
    showFeature: boolean;
    modalIsOpen: boolean;
}

class FeatureButtonComponent extends React.Component<IFeatureButtonProps, IFeatureButtonState> {
    constructor(props: IFeatureButtonProps) {
        super(props);
        this.state = {
            showFeature: false,
            modalIsOpen: false
        };
    }

    public componentDidMount() {

    }

    public showFeatureHandle() {
        this.setState({ showFeature: !this.state.showFeature });
    }

    public closeModalMaps(value: boolean) {
        this.setState({ modalIsOpen: value });
    }

    public openModalMaps() {
        this.setState({ modalIsOpen: true });
    }
    
    public iframeMapsOnload() {
        const { t, propsList } = this.props;
        helper.postMessageToIframe('iframe-maps-list-popup', propsList, t('MORE_INFO') );
        this.bindEvent(window, 'message', (e: any) => {
            if (isUndefined(e.data.type) && e.data.type === 'maps') {
                if (isUndefined(e.data.mess) && isNumber(e.data.mess)) {
                    navigate(`/property/${e.data.mess}`);
                }
            }
        });
    }

    public bindEvent(element: any, eventName: any, eventHandler: any) {
        if (element.addEventListener) {
            element.addEventListener(eventName, eventHandler, false);
        } else if (element.attachEvent) {
            element.attachEvent('on' + eventName, eventHandler);
        }
    }

    public handleGotoDemandRegister() {
        navigate('/demand-register');
    }

    public render() {
        return (
            <div id='feature-button'>
                <button type='button' onClick={this.showFeatureHandle.bind(this)} className='' data-toggle='dropdown' aria-haspopup='true' aria-expanded='true'>
                    {!this.state.showFeature && <FontAwesomeIcon name='ellipsis-h' />}
                    {this.state.showFeature && <FontAwesomeIcon name='times' />}
                </button>
                <div className={`${this.state.showFeature ? 'show' : 'hidden'}`} x-placement='top-start' style={{ position: 'absolute', transform: 'translate3d(0px, -165px, 0px)', top: '15px', left: '0px', willChange: 'transform' }}>
                    <button onClick={this.handleGotoDemandRegister.bind(this)} className='dropdown-item'><FontAwesomeIcon name='pencil' size='2x' /></button>
                    <button onClick={this.openModalMaps.bind(this)} className='dropdown-item'><FontAwesomeIcon name='map' /></button>
                </div>
                <Modal modalIsOpenProps={this.state.modalIsOpen}
                    closeModal={this.closeModalMaps.bind(this)}
                    children={
                        <div style={{ height: typeof window !== 'undefined' ? (window.document.documentElement!.clientHeight * 3 / 4 + 30) : '500px' }}>
                            <div className='content map' style={{ height: '100%' }}>
                                <iframe style={{ border: 'none' }} onLoad={this.iframeMapsOnload.bind(this)} id='iframe-maps-list-popup' src='/maps/maps-list-iframe.html' width='100%' height='100%' ></iframe>
                            </div>
                        </div>} width='500px' />
            </div>
        );
    }
}

export default translate('translations')(FeatureButtonComponent);
