import * as React from 'react';
import { translate } from 'react-i18next';
import 'react-image-gallery/styles/css/image-gallery-no-icon.css';
import './fixed-icon-home-slider.css';
// import 'font-awesome/css/font-awesome.min.css';
import ImageGallery from 'react-image-gallery';
import Img from 'gatsby-image';
// import SliderLoader from '../../LoaderComponent/slider-home-loader';
import * as helper from '../../../helper/helpers';
import { debounce } from 'lodash';
import * as FontAwesomeIcon from 'react-fontawesome';

interface ISliderProps {
    i18n: any;
    t: any;
    ImageList: any[];
    descriptionsImg?: any[];
    showThumbnails?: boolean;
    autoPlay?: boolean;
    slideDuration?: number;
    slideInterval?: number;
}

interface ISliderState {
    isMobile: boolean;
    images: any[];
}

class SliderComponent extends React.Component<ISliderProps, ISliderState> {
    constructor(props: ISliderProps) {
        super(props);
        this.state = {
            isMobile: true,
            images: []
        };
    }

    public componentDidMount() {
        if (typeof window !== 'undefined') {
            if (window.innerWidth < 768) {
                this.setState({ isMobile: true });
            } else {
                this.setState({ isMobile: false });
            }

            window.addEventListener('resize', debounce(() => {// lodash debounce method.

                if (window.innerWidth < 768) {
                    this.setState({ isMobile: true });
                } else {
                    this.setState({ isMobile: false });
                }

            }, 10));
        }
        const { ImageList } = this.props;
        const images = this.prepareImageForHomeSlider(ImageList);

        this.setState({images});

    }

    public prepareImageForHomeSlider(ImageList: any) {
        const { i18n } = this.props;
        const imagesList: any[] = [];
        if (ImageList.length > 0) {
            ImageList.forEach((img: any) => {
                imagesList.push({
                    description: img.description.public,
                    descriptionHtml: helper.getContentWithLanguage(img.description, i18n.language),
                    resolutions: !!img.image.childImageSharp.resolutions ? img.image.childImageSharp.resolutions : null,
                    sizes: !!img.image.childImageSharp.sizes ? img.image.childImageSharp.sizes : null
                });
            });
            return imagesList;
        } else {
            return imagesList;
        }
    }

    public HomeImageGalleryRenderer(item: any) {
        return (
            <div className='image-gallery-image home-image-slider'>
                <Img className='slider-image' sizes={item.sizes} alt={item.originalAlt} />
                {item.description &&
                    <div className='image-gallery-description home-description-slider' style={{ whiteSpace: 'pre-wrap' }} >
                        <span className='slider-description' dangerouslySetInnerHTML={{ __html: item.descriptionHtml }}></span>
                    </div>
                }
            </div>
        );
    }

    public renderRightNav(onClick: any, disabled: any) {
        return (
            <button
                aria-label='image-gallery-right-nav-cus'
                className='image-gallery-right-nav-cus'
                disabled={disabled}
                onClick={onClick} >
                <FontAwesomeIcon name='arrow-circle-right' />
            </button >
        );
    }

    public renderLeftNav(onClick: any, disabled: any) {
        return (
            <button
                aria-label='image-gallery-left-nav-cus'
                className='image-gallery-left-nav-cus'
                disabled={disabled}
                onClick={onClick} >
                <FontAwesomeIcon name='arrow-circle-left' />
            </button>
        );
    }

    public render() {
        return (
            <div>
                {!this.state.isMobile && this.state.images.length > 0 && <ImageGallery
                    items={this.state.images}
                    lazyLoad={true}
                    showThumbnails={this.props.showThumbnails}
                    showFullscreenButton={false}
                    showPlayButton={false}
                    autoPlay={this.props.autoPlay ? !this.props.autoPlay : false}
                    slideDuration={this.props.slideDuration ? this.props.slideDuration : 500}
                    slideInterval={this.props.slideInterval ? this.props.slideInterval : 4000}
                    renderItem={this.HomeImageGalleryRenderer.bind(this)}
                    renderLeftNav={this.renderLeftNav.bind(this)}
                    renderRightNav={this.renderRightNav.bind(this)}
                />}
                {this.state.isMobile && this.state.images.length > 0 && <div style={{height: '200px'}}><Img className='slider-image' sizes={this.state.images[0].sizes} alt={this.state.images[0].originalAlt} /></div>}
            </div>
        );
    }
}

export default translate('translations')(SliderComponent);
