import * as React from 'react';
import { translate } from 'react-i18next';
import 'react-image-gallery/styles/css/image-gallery.css';
import ImageGallery from 'react-image-gallery';
import ProgressiveImage from 'react-progressive-image';

interface ISliderProps {
    i18n: any;
    t: any;
    ImageList: any[];
    widthImg: string; // for image on detail page
    descriptionsImg?: any[];
    showThumbnails: boolean;
    autoPlay: boolean;
    slideDuration?: number;
    slideInterval?: number;
}

interface ISliderState {
    lightboxIsOpen: boolean;
    imageIndex: number;
    fullScreen: boolean;
    images: any[];
}

class SliderComponent extends React.Component<ISliderProps, ISliderState> {
    constructor(props: ISliderProps) {
        super(props);
        this.state = {
            lightboxIsOpen: false,
            imageIndex: 0,
            fullScreen: false,
            images: []
        };
    }

    public componentDidMount() {
        const { ImageList } = this.props;
        let images: any[] = [];
        images = this.prepareImageForDetailSlider(ImageList);
        this.setState({images});
    }

    public prepareImageForDetailSlider(ImageList: any[]) {
        const imagesList: any[] = [];
        if (ImageList.length > 0) {
            ImageList.forEach((img: any) => {
                imagesList.push({
                    original: img + '?w=' + this.props.widthImg + '&auto=format,compress',
                    originalBlur: img + '?w=27&q=50&auto=format,compress',
                    thumbnail: img + '?w=' + Math.floor((parseInt(this.props.widthImg!, 10) / 3)) + '&auto=format,compress',
                });
            });
            return imagesList;
        } else {
            return imagesList;
        }
    }

    public prepareImageFullScreen(ImageList: any[]) {
        const imagesList: any[] = [];
        if (ImageList.length > 0) {
            ImageList.forEach((img: any) => {
                imagesList.push({
                    src: `${img}?w=940&auto=format,compress`,
                    alt: ''
                });
            });
            return imagesList;
        } else {
            return imagesList;
        }
    }

    public DetailImageGalleryRenderer(item: any) {
        return (
            <div className='image-gallery-image detail-image'>
                <ProgressiveImage src={item.original} placeholder={item.originalBlur} >
                    {(src: any, loading: any) => (
                        <img src={src} style={{ opacity: loading ? 0.3 : 1, transition: 'opacity 0.5s ease 0s' }} alt='' />
                    )}
                </ProgressiveImage>
            </div>
        );
    }

    public gotoPrevLightboxImage() {
        const imageIndex = this.state.imageIndex;
        this.setState({ imageIndex: imageIndex - 1 });
    }

    public gotoNextLightboxImage() {
        const imageIndex = this.state.imageIndex;
        this.setState({ imageIndex: imageIndex + 1 });
    }

    public closeLightbox() {
        this.setState({ lightboxIsOpen: false });
    }

    public showLightBox() {
        this.setState({ lightboxIsOpen: true });
    }

    public gotoImageThumbnail(index: number) {
        this.setState({ imageIndex: index });
    }

    public render() {
        return (
            <div>
                {this.state.images.length > 0 && <ImageGallery
                    ref='detailGallery'
                    items={this.state.images}
                    lazyLoad={true}
                    showThumbnails={this.props.showThumbnails}
                    showFullscreenButton={false}
                    showPlayButton={false}
                    autoPlay={this.props.autoPlay ? this.props.autoPlay : false}
                    slideDuration={this.props.slideDuration ? this.props.slideDuration : 500}
                    slideInterval={this.props.slideInterval ? this.props.slideInterval : 4000}
                    renderItem={this.DetailImageGalleryRenderer.bind(this)}
                    // onClick={this.showLightBox.bind(this)}
                    // onSlide={(currentIndex: number) => this.setState({ imageIndex: currentIndex })}
                />}
            </div>
        );
    }
}

export default translate('translations')(SliderComponent);
