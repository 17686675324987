import * as React from 'react';
import { translate } from 'react-i18next';
// import * as Modal from 'react-modal';
import Modal from 'react-responsive-modal';

interface IModalProps {
    i18n?: any;
    t?: any;
    width?: string;
    maxWidth?: string;
    children: any;
    modalIsOpenProps: boolean;
    closeModal: any;
}

interface IModalState {
    modalIsOpen: boolean;
    isMobile: boolean;
}

class ModalComponent extends React.Component<IModalProps, IModalState> {
    constructor(props: IModalProps) {
        super(props);

        this.state = {
            modalIsOpen: false,
            isMobile: false
        };
        
        this.openModal = this.openModal.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    public componentDidMount() {
        let isMobile = false;
        if(typeof window !== 'undefined') {
            if(window.innerWidth < 767) {
                isMobile = true;
            }
        }
        this.setState({ isMobile });
    }

    public UNSAFE_componentWillReceiveProps(nextProps: any) {
        let isMobile = false;
        if(typeof window !== 'undefined') {
            if(window.innerWidth < 767) {
                isMobile = true;
            }
        }
        this.setState({ isMobile });
    }

    public componentWillUnmount() {

    }

    public componentWillReceiveProps(nextProps: any) {
        this.setState({modalIsOpen: nextProps.modalIsOpenProps});
    }

    public openModal() {
        document.body.setAttribute('style', 'position: fixed');
        this.setState({modalIsOpen: true});
    }
    
    public afterOpenModal() {
        // references are now sync'd and can be accessed.
    }
    
    public async closeModal() {
        if(typeof this.props.closeModal === 'function') {
            document.body.setAttribute('style', 'position: relative');
            this.props.closeModal(!this.state.modalIsOpen);
        }
    }

    public render() {
        return (
            <Modal open={this.state.modalIsOpen} blockScroll={false} onClose={this.closeModal} styles={{modal: { width: !!this.props.width? this.props.width : '800px', maxWidth: !!this.props.maxWidth? this.props.maxWidth : '1920px' }}}>
                    {this.props.children}
            </Modal>
        );
    }
}

export default translate('translations')(ModalComponent);
