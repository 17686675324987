import * as React from 'react';
import { translate } from 'react-i18next';
import * as helper from '../../helper/helpers';
// import 'font-awesome/css/font-awesome.min.css';
import * as FontAwesomeIcon from 'react-fontawesome';

interface IContactDetailsProps {
    i18n?: any;
    t?: any;
    data: any;
}

class ContactDetailsComponent extends React.Component<IContactDetailsProps> {
    constructor(props: IContactDetailsProps) {
        super(props);
    }

    public render() {
        const { t, data } = this.props;
        return (
            <div className='contact_details'>
                <div className='contact_details_item'>
                    <h4>{t('ADDRESS')}</h4>
                    <FontAwesomeIcon name='home' className='icons-marker' />
                    <div className='contact_address'>
                    {data.name}<br />
                    <span style={{lineHeight: '1.3em'}}>{data.street_houseNumber}<br />
                    </span><span style={{lineHeight: '1.3em'}}>{data.zip_city}</span>
                </div>
                <div className='clearfix' style={{height: '1px'}}></div>
                </div>
                <div className='contact_details_item'>
                    <h4>Telefoons:</h4>
                    <FontAwesomeIcon name='phone' className='icons-marker' />
                    <div className='contact_details_telephone'>{data.phoneNumber}</div>
                    <div className='clearfix' style={{height: '1px'}}></div>
                    <FontAwesomeIcon name='mobile' className='icons-marker' />
                    <div className='contact_details_fax'>{data.gsm}</div>
                <div className='clearfix' style={{height: '1px'}}></div>
                </div>
                <div className='contact_details_item'>
                    <h4>Email:</h4>
                    <FontAwesomeIcon name='envelope' className='icons-marker' />
                    <div className='contact_details_emailto'><a href='mailto:info@immovanparijs.be'>{data.email}</a></div>
                    <div className='clearfix' style={{height: '1px'}}></div>
                </div>
                <div className='contact_details_item'>
                    <h4>Openingsuren:</h4>
                    <FontAwesomeIcon name='clock-o' className='icons-marker' />
                    <div className='contact_address' dangerouslySetInnerHTML={{__html: data.openhours }}>
                    </div>
                <div className='clearfix' style={{height: '1px'}}></div>
                </div>
                <div className='contact_details_item'>
                    <h4>Wettelijke verplichtingen:</h4>
                    <div className='contact_address' dangerouslySetInnerHTML={{__html: helper.compareImage(data.ipi) }}>
                    </div>
                    <div className='clearfix' style={{height: '1px'}}></div>
                </div>
                <div className='clearfix'></div>
            </div>
        );
    }
}

export default translate('translations')(ContactDetailsComponent);
