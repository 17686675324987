import * as React from 'react';
import { translate } from 'react-i18next';
import Modal from '../../ModalComponent/modal';

interface IStreetViewDetailProps {
    i18n?: any;
    t?: any;
    prop: any;
}

interface IStreetViewDetailState {
    iframeSrc: string;
    modalIsOpen: boolean;
}

class StreetViewComponent extends React.Component<IStreetViewDetailProps, IStreetViewDetailState> {
    constructor(props: IStreetViewDetailProps) {
        super(props);
        this.state = {
            iframeSrc: '',
            modalIsOpen: false
        };
    }

    public componentDidMount() {
        const { prop } = this.props;
        const iframeSrc = `https://maps.google.be/maps?q=${prop.Street}+${prop.HouseNumber}+${prop.Zip}+${prop.City}&layer=c&z=17&iwloc=A&sll=${prop.GoogleX},${prop.GoogleY}&cbp=13,231.3,0,0,0&cbll=${prop.GoogleX},${prop.GoogleY}&t=h&z=14&iwloc=near&source=embed&output=svembed`;
        this.setState({ iframeSrc: iframeSrc });
    }

    public componentWillReceiveProps(nextProps: any) {
        if(this.props.prop !== nextProps.prop) {
            const { prop } = nextProps;
            const iframeSrc = `https://maps.google.be/maps?q=${prop.Street}+${prop.HouseNumber}+${prop.Zip}+${prop.City}&layer=c&z=17&iwloc=A&sll=${prop.GoogleX},${prop.GoogleY}&cbp=13,231.3,0,0,0&cbll=${prop.GoogleX},${prop.GoogleY}&t=h&z=14&iwloc=near&source=embed&output=svembed`;
            this.setState({ iframeSrc: iframeSrc });
        }
    }

    public openModalView() {
        this.setState({modalIsOpen: true});
    }

    public closeModalView(value: boolean) {
        this.setState({modalIsOpen: value});
    }

    public render() {
        const { t } = this.props;
        return (
            <div className='group text-center'>
                <Modal modalIsOpenProps={this.state.modalIsOpen} 
                        closeModal={this.closeModalView.bind(this)}  
                        children={
                            <div style={{width:  typeof window !== 'undefined'? (window.document.documentElement!.clientWidth * 3 / 4) : '500px' , height:  typeof window !== 'undefined'? (window.document.documentElement!.clientHeight * 3 / 4  + 30) : '500px'}}>
                            <div className='caption'>{t('STREET VIEW')}</div>
                                <div className='content map' style={{width:  typeof window !== 'undefined'? (window.document.documentElement!.clientWidth * 3 / 4) : '500px' , height:  typeof window !== 'undefined'? (window.document.documentElement!.clientHeight * 3 / 4) : '500px'}}>
                                    <iframe id='streetViewFrame'
                                        title='streetViewFrame'
                                        width='100%'
                                        height='100%'
                                        scrolling='no'
                                        src={this.state.iframeSrc}></iframe>
                                </div>
                        </div>}  width={typeof window !== 'undefined'? (window.document.documentElement!.clientWidth * 3 / 4).toString() : '500px'} />
                <div className='caption'>{t('STREET VIEW')}</div>
                <div className='content map'>
                    <iframe id='streetViewFrame'
                        title='streetViewFrame'
                        width='100%'
                        height='100%'
                        scrolling='no'
                        src={this.state.iframeSrc}></iframe>
                </div>
                <a onClick={this.openModalView.bind(this)} className='map-link'>{t('ENLARGE_THE_MAP')}</a>
            </div>
        );
    }
}

export default translate('translations')(StreetViewComponent);
