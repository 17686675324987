import * as React from 'react';
import { formatCurrency } from '../../../../helper/helpers';
import { translate } from 'react-i18next';

interface IHtmlFormatProps {
    title: string;
    value: any;
    htmtFormat?: boolean;
    priceStringHtml?: boolean;
    booleanHtml?: boolean;
    stringHtml?: boolean;
    numberHtml?: boolean;
    addText?: string;
    t?: any;
}

class HtmlFormat extends React.Component<IHtmlFormatProps> {
    constructor(props: IHtmlFormatProps) {
        super(props);
    }

    public renderPriceStringHTML(title: string, value: any, addText?: string) {
        if (value === null) {
            return null;
        }

        /// !!! = not isset
        if (addText === '') {
            addText = '€';
        }

        if (value > 0 && value !== null) {
            return (
                <div className='field'>
                    <div className='name'>{title}</div>
                    <div className='sep'>:</div>
                    <div className='value'>
                        {formatCurrency(value)}
                    </div>
                    <div className='clear'></div>
                </div>);
        }

        return null;
    }

    public renderStringHTML(title: string, value: any, addText?: string) {
        if (value === null) {
            return null;
        }

        if (addText !== '') {
            value += ` ${addText}`;
        }

        if (value !== '' && value !== null) {
            return (
                <div className='field'>
                    <div className='name'>{title}</div>
                    <div className='sep'>:</div>
                    <div className='value'>{value}</div>
                    <div className='clear'></div>
                </div>);
        }

        return null;
    }

    public renderBooleanHTML(title: string, value: any, addText?: string) {
        const { t } = this.props;
        if (value === false) {
            value = t('NO_TEXT');
        }

        if (value === true) {
            value = t('YES_TEXT');
        }

        if (addText !== '') {
            value += ` ${addText}`;
        }

        if (value !== '' && value !== null) {
            return (
                <div className='field'>
                    <div className='name'>{title}</div>
                    <div className='sep'>:</div>
                    <div className='value'>{value}</div>
                    <div className='clear'></div>
                </div>);
        }

        return null;
    }

    public renderNumberHTML(title: string, value: any, addText?: string) {
        if (!value) {
            return null;
        }

        if (addText !== '') {
            value += ` ${addText}`;
        }

        if (value !== 0 && value !== null) {
            return (
                <div className='field'>
                    <div className='name'>{title}</div>
                    <div className='sep'>:</div>
                    <div className='value'>{value}</div>
                    <div className='clear'></div>
                </div>);
        }

        return null;
    }

    public render() {
        let { stringHtml, numberHtml, priceStringHtml, addText, title, value, booleanHtml } = this.props;

        if (!!!booleanHtml) {
            booleanHtml = false;
        }

        if (!!!stringHtml) {
            stringHtml = false;
        }

        if (!!!numberHtml) {
            numberHtml = false;
        }

        if (!!!priceStringHtml) {
            priceStringHtml = false;
        }

        if (!!!addText) {
            addText = '';
        }

        if (!!!title) {
            title = '';
        }

        if (!!!value) {
            value = null;
        }
        
        return (
            <div>
                {stringHtml && this.renderStringHTML(title, value, addText)}
                {numberHtml && this.renderNumberHTML(title, value, addText)}
                {priceStringHtml && this.renderPriceStringHTML(title, value, addText)}
                {booleanHtml && this.renderBooleanHTML(title, value, addText)}
            </div>
        );
    }
}

export default translate('translations')(HtmlFormat);
