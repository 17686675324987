import * as React from 'react';
import PropertyComponent from './property';

interface IPropertyListComponentProps {
    ListProperty: any[];
}

export default class PropertyListComponent extends React.Component<IPropertyListComponentProps, {}> {

    constructor(props: IPropertyListComponentProps) {
        super(props);
        this.state = {};
    }

    public render() {
        return (
            <div>
                {this.props.ListProperty.map((prop, i) => {
                    const propData = prop;
                    return <PropertyComponent key={i} data={propData} />;
                })}
            </div>
        );
    }
}
