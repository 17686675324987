import * as React from 'react';
import { translate } from 'react-i18next';
import HtmlFormat from '../html-format/html-format';

interface IBottomRowTemplateProps {
    i18n?: any;
    t?: any; // translate
    data: any;
}

class BottomRowTemplate extends React.Component<IBottomRowTemplateProps> {
    constructor(props: IBottomRowTemplateProps) {
        super(props);
    }

    public render() {
        const { i18n } = this.props;
        return (
            <div id='bottom-row'>
                <div id='bottom-row-in'>
                    <div className='row-container'>
                        <div className='container-fluid'>
                            <div className='row-fluid'>
                                {this.props.data.map((arr: any[], i: number) => {
                                    return (
                                        <div className='content-inner row-fluid' key={i}>
                                            {arr.map((item: any, i: number) => {
                                                return <HtmlFormat dataContext={item} key={i} language={i18n.language} />;
                                            })}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default translate('translations')(BottomRowTemplate);
