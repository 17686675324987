import * as React from 'react';
import { translate } from 'react-i18next';
import * as helper from '../../helper/helpers';

interface IPageProps {
  i18n: any;
  t: any; // translate
  pageContext: any;
}

interface IPageState {
  itemsPerRows: number;
  titlePage: string;
  body: string;
}

class BlogTemplate extends React.Component<IPageProps, IPageState> {
  constructor(props: IPageProps) {
    super(props);
    this.state = {
      itemsPerRows: 4,
      titlePage: '',
      body: '',
    };
  }

  public componentDidMount() {
    const { i18n, pageContext } = this.props;
    const blog = !!pageContext.allBlogJson ? pageContext.allBlogJson : {};
    const { titlePage, body } = blog;
    this.setState({ titlePage: helper.getContentWithLanguage(titlePage, i18n.language), body: helper.getContentWithLanguage(body, i18n.language) });
  }

  public async componentWillReceiveProps(nextProps: any) {
    const { i18n, pageContext } = this.props;
    const blog = !!pageContext.allBlogJson ? pageContext.allBlogJson : {};
    const { titlePage, body } = blog;
    this.setState({ titlePage: helper.getContentWithLanguage(titlePage, i18n.language), body: helper.getContentWithLanguage(body, i18n.language) });
  }

  public render() {
    const { i18n } = this.props;
    helper.setLanguageDefault(i18n.language);
    return (
      <div className='content-inner row-fluid'>
        <div className='page-item page-item__services'>
          <div className='item_header'>
            <h3>{this.state.titlePage}</h3>
          </div>
          <div className='item_fulltext' style={{ whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={{ __html: this.state.body }}>
          </div>
        </div>
      </div>
    );
  }
}

export default translate('translations')(BlogTemplate);
