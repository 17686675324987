import * as React from 'react';
import { translate } from 'react-i18next';
import { Link } from 'gatsby';
import PropertyListPage from '../../components/PropertyComponent/PropertiesListComponent';
import PropertyListScroll from '../../components/PropertyComponent/PropertiesListScrollComponent';
import PropertyListModule from '../../components/PropertyComponent/PropertiesNewComponent';
import PropertyDetail from '../child-templates/property-detail-tmpl';

import HomeSilder from '../../components/SliderComponent/home-slider/home-slider';
// import HomeSilder from '../../components/SliderComponent/slider/slider';

import DemandRegister from '../../components/FormComponent/demand-register';
import ContactDemand from '../../components/FormComponent/contact-demand';
import SendToFriend from '../../components/FormComponent/send-to-friend';
import SiteContact from '../../components/FormComponent/site-contact';
import ContactDetails from '../../components/GeneralComponent/contact-details';

import * as helper from '../../helper/helpers';
import BlogModule from '../child-templates/blog-module-tmpl';
import BlogPage from '../child-templates/blog-tmpl';
import * as _ from 'lodash';

interface IHtmlFormatProps {
    dataContext?: any;
    t?: any;
    i18n?: any;
    language: string;
}

interface IHtmlFormatState {
    lang: string;
}

class HtmlFormat extends React.Component<IHtmlFormatProps, IHtmlFormatState> {
    constructor(props: IHtmlFormatProps) {
        super(props);
        const { i18n } = props;
        this.state = {
            lang: i18n.language
        };
    }

    public componentDidMount() {
        this.setState({ lang: this.props.language });
    }

    public componentWillReceiveProps(nextProps: any) {
        this.setState({ lang: nextProps.language });
    }

    public renderBlog(module: boolean) {
        const { dataContext } = this.props;
        if (module === true) {
            const width = `span${!!dataContext.span && dataContext.span !== null && dataContext.span !== '' ? dataContext.span : '12'}`;
            return <BlogModule module={module} dataContext={dataContext} widthModule={width} language={this.state.lang} />;
        } else {
            return <BlogPage pageContext={dataContext}  />;
        }
    }

    public renderCategory(module: boolean) {
        const { dataContext } = this.props;
        const width = `span${!!dataContext.span && dataContext.span !== null && dataContext.span !== '' ? dataContext.span : '12'}`;
        let { blogWithCategory } = dataContext.data;
        const itemsPerRows = 3;
        blogWithCategory = _.chunk(blogWithCategory, itemsPerRows);
        return (
            <div className={width} style={{ width: '100%' }}>
                <div className='page-category page-category__services'>
                    {dataContext.titlePage.public &&
                        <div className='page_header'>
                            <h2>{helper.getContentWithLanguage(dataContext.titlePage, this.state.lang)}</h2>
                        </div>}
                    {dataContext.data.category.titlePage.public &&
                        <div className='page_header'>
                            <h2>{helper.getContentWithLanguage(dataContext.data.category.titlePage, this.state.lang)}</h2>
                        </div>}
                    {blogWithCategory.map((arr: any[], i: number) => {
                        return (
                            <div className='row-fluid' key={i}>
                                {arr.map((blog: any, i: number) => {
                                    let classP = 'span' + Math.trunc(12 / itemsPerRows).toString() + ' service ';
                                    if ((i % itemsPerRows) === (itemsPerRows - 1)) {
                                        classP = classP + 'last';
                                    }
                                    const { titlePage, title, date, body, category } = blog.node;
                                    const path = `/${category.toLowerCase()}/${title.toLowerCase().replace(' ', '-')}`;
                                    if (helper.getContentWithLanguage(titlePage, this.state.lang) !== '') {
                                        return (
                                            <div className={classP} key={i}>
                                                <div className={'item'}>
                                                    <div className='item_header'>
                                                        <h3 className='item_title'><Link to={path}>{helper.getContentWithLanguage(titlePage, this.state.lang)}</Link></h3>
                                                        <h5 className='item_date'>{date}</h5>
                                                    </div>
                                                    <div className='item_introtext' style={{ whiteSpace: 'pre-wrap', minHeight: '36px' }} dangerouslySetInnerHTML={{ __html: `${helper.getContentWithLanguage(body, this.state.lang).substring(0, 60)}...` }}>
                                                    </div>
                                                </div>
                                            </div>);
                                    } else {
                                        return null;
                                    }
                                })}
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }

    public renderSiteInfo(module: boolean) {
        const { dataContext } = this.props;
        return (
            <ContactDetails data={dataContext.data} />
        );
    }

    public renderSlider(module: boolean) {
        const { dataContext } = this.props;
        const width = `span${!!dataContext.span && dataContext.span !== null && dataContext.span !== '' ? dataContext.span : '12'}`;
        return <div className={`moduletable ${module ? '' : width}`}>
            <HomeSilder ImageList={dataContext.data}
                autoPlay={true}
                showThumbnails={false}
                slideDuration={1000}
                slideInterval={8000} />
        </div>;
        // return <div className={`moduletable ${module ? '' : width}`}><HomeSilder ImageList={dataContext.data} /></div>;
    }

    public renderPropertyList(module: boolean) {
        const { dataContext } = this.props;
        const width = `span${!!dataContext.span && dataContext.span !== null && dataContext.span !== '' ? dataContext.span : '12'}`;
        if (dataContext.data.setting.showing === 'module') {
            const title = helper.getContentWithLanguage(dataContext.titlePage, this.state.lang);
            const propertiesListProps: any[] = dataContext.data.propertiesList.filter((item: any) => item.language === this.state.lang);
            return (
                <div className={`moduletable ${module ? '' : width}`}>
                    <PropertyListModule
                        language={this.state.lang}
                        title={title}
                        propertiesListProps={propertiesListProps}
                    />
                </div>
            );
        } else if (dataContext.data.setting.showing === 'page') {
            return (
                <PropertyListPage
                    language={this.state.lang}
                    limitPageProps={12}
                    itemsPerRowProps={4}
                    propertiesListProps={!!dataContext.data.propertiesList ? dataContext.data.propertiesList.filter((item: any) => item.language === this.state.lang) : []}
                    webIDsProps={!!dataContext.data.webIDs ? dataContext.data.webIDs.filter((item: any) => item.language === this.state.lang) : []}
                    citysProps={!!dataContext.data.citys ? dataContext.data.citys : []}
                    rangePricesProps={!!dataContext.data.rangePrices ? dataContext.data.rangePrices : []}
                />

            );
        } else if (dataContext.data.setting.showing === 'infinity') {
            return (
                <PropertyListScroll
                    language={this.state.lang}
                    itemsPerRowProps={4}
                    propertiesListProps={!!dataContext.data.propertiesList ? dataContext.data.propertiesList.filter((item: any) => item.language === this.state.lang) : []}
                    webIDsProps={!!dataContext.data.webIDs ? dataContext.data.webIDs.filter((item: any) => item.language === this.state.lang) : []}
                    citysProps={!!dataContext.data.citys ? dataContext.data.citys : []}
                    rangePricesProps={!!dataContext.data.rangePrices ? dataContext.data.rangePrices : []}
                />
            );
        }
        return null;
    }

    public renderContact(module: boolean) {
        const { dataContext } = this.props;
        return (
            <div>
                {dataContext.relations.contact === 'demand-register' && <DemandRegister />}
                {dataContext.relations.contact === 'send-to-friend' && <SendToFriend isPopup={false} />}
                {dataContext.relations.contact === 'contact-demand' && <ContactDemand webIDsProps={dataContext.data.webIDs.filter((item: any) => item.language === this.state.lang)} />}
                {dataContext.relations.contact === 'site-contact' && <SiteContact />}
            </div>
        );
    }

    public renderPropertyDetail() {
        const { dataContext } = this.props;
        // console.log('renderPropertyDetail', dataContext);
        return (
            <PropertyDetail dataContext={dataContext} />
        );
    }

    public render() {
        const { dataContext } = this.props;

        if (dataContext.isModule === true) {
            const width = `span${!!dataContext.span && dataContext.span !== null && dataContext.span !== '' ? dataContext.span : '12'}`;
            return (
                <div className={`moduletable ${width}`}>
                    {dataContext.type === 'blog' && this.renderBlog(true)}
                    {dataContext.type === 'category' && this.renderCategory(true)}
                    {dataContext.type === 'slider' && this.renderSlider(true)}
                    {dataContext.type === 'siteInfo' && this.renderSiteInfo(true)}
                    {dataContext.type === 'propertyList' && this.renderPropertyList(true)}
                    {dataContext.type === 'contact' && this.renderContact(true)}
                </div>
            );
        } else {
            return (
                <div className='content-inner row-fluid'>
                    {dataContext.type === 'propertyDetail' && this.renderPropertyDetail()}
                    {dataContext.type === 'blog' && this.renderBlog(false)}
                    {dataContext.type === 'category' && this.renderCategory(false)}
                    {dataContext.type === 'slider' && this.renderSlider(false)}
                    {dataContext.type === 'siteInfo' && this.renderSiteInfo(false)}
                    {dataContext.type === 'propertyList' && this.renderPropertyList(false)}
                    {dataContext.type === 'contact' && this.renderContact(false)}
                </div>
            );
        }

    }
}

export default translate('translations')(HtmlFormat);
