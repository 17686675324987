import * as React from 'react';
import { translate } from 'react-i18next';
import HtmlFormat from './general-detail/htmlFormat';
import Modal from '../../ModalComponent/modal';
import Contact from '../../FormComponent/contact';
// import 'font-awesome/css/font-awesome.min.css';

interface IPanelRightProps {
    i18n?: any;
    t?: any;
    prop: any;
}

interface IPanelRightState {
    modalIsOpen: boolean;
    childModal: any;
    ContactName: string;
}

class PanelRightComponent extends React.Component<IPanelRightProps, IPanelRightState> {
    constructor(props: IPanelRightProps) {
        super(props);
        this.state = {
            modalIsOpen: false,
            ContactName: '',
            childModal: {},
        };
    }

    public getAddress(prop: any) {
        let address: string = '';
        if (prop) {
            if (prop.PublishAddress === true) {
                address += prop.Street + ' ' + prop.HouseNumber;
            }

            if (address !== '') {
                address += ', ';
            }
            address += prop.Zip + ' ' + this.getCityAndCountry(prop.City, prop.CountryID, prop.CountryName);
        }
        return address;
    }

    public getCityAndCountry(city: string, countryID?: number, countryName?: string): string {
        let country = '';
        if (countryID && countryID !== 10) {
            if (countryName) {
                country = ` (${countryName})`;
            }
        }
        return city + country;
    }

    public getDocumentIcon(file: string) {
        if (!!file) {
            const type: string = file.substr(-4, 4).toLocaleLowerCase();
            const path = `/layouts/properties-images/doc/`;
            switch (type) {
                case '.pdf': return path + 'icon_pdf.gif';
                case '.xls': return path + 'icon_xls.gif';
                case '.doc': return path + 'icon_doc.gif';
                case '.jpg':
                case '.jpeg':
                case '.gif':
                case '.bmp':
                case '.png':
                    return path + 'icon_jpg.gif';
                default:
                    return path + 'icon_file.gif';
            }
        }
        return '';
    }

    public openModalContactForInfo() {
        this.setState({modalIsOpen: true, ContactName: 'CONTACT_FOR_MORE_INFO' });
    }

    public openModalContactForVisit() {
        this.setState({modalIsOpen: true, ContactName: 'CONTACT_FOR_VISIT' });
    }

    public closeModalContact(value: boolean) {
        this.setState({modalIsOpen: value});
    }

    public render() {
        const { t, prop } = this.props;
        return (
            <div className='group-container'>
                <Modal modalIsOpenProps={this.state.modalIsOpen} closeModal={this.closeModalContact.bind(this)} width='800px' children={<Contact data={this.props.prop} contactName={this.state.ContactName} isPopup={true} />}/>
                <div className='group hidden-print' style={{ padding: '20px 7px' }}>
                    <div style={{ marginBottom: '15px' }}>
                        <button style={{textAlign: 'left'}} id='form_contact_me_for_more_info' className='btn btn-block' onClick={this.openModalContactForInfo.bind(this)}>
                            {t('ASK_FOR_MORE_INFO')}
                        </button>
                    </div>
                    <div className=''>
                        <button style={{textAlign: 'left'}} id='form_contact_me_for_visit' className='btn btn-block' onClick={this.openModalContactForVisit.bind(this)}>
                            {t('ASK_FOR_A_VIST')}
                        </button>
                    </div>
                </div>
                <div className='group'>
                    <div className='caption'>{t('GENERAL')}</div>
                    <div className='content'>
                        {prop.PublishPrice === true && <HtmlFormat title={t('PRICE')} value={prop.Price} priceStringHtml={true} />}
                        {prop.PublishPrice === false && <HtmlFormat title={t('PRICE')} value={t('PRICE_ON_DEMAND')} stringHtml={true} />}
                        {<HtmlFormat title={t('RATEABLE')} value={prop.CadastrallIncomeNonIndexed} priceStringHtml={true} />}
                        {<HtmlFormat title={t('NUMBER_OF_BED_ROOMS')} value={prop.NumberOfBedRooms} numberHtml={true} />}
                        {<HtmlFormat title={t('NUMBER_OF_BATH_ROOMS')} value={prop.NumberOfBathRooms} numberHtml={true} />}
                        {<HtmlFormat title={t('NUMBER_OF_SHOWER_ROOMS')} value={prop.NumberOfShowerRooms} numberHtml={true} />}
                        {<HtmlFormat title={t('GARAGE')} value={prop.NumberOfGarages} numberHtml={true} />}
                        {<HtmlFormat title={t('ADDRESS')} value={this.getAddress(prop)} stringHtml={true} />}
                        {<HtmlFormat title={t('Environment Name')} value={prop.EnviromentName} stringHtml={true} />}
                        {<HtmlFormat title={t('SURFACE_TOTAL')} value={prop.SurfaceTotal} numberHtml={true} addText='m&sup2;' />}
                        {<HtmlFormat title={t('SURFACE_LIVING')} value={prop.SurfaceLiving} numberHtml={true} addText='m&sup2;' />}
                        {<HtmlFormat title={t('SURFACE_GROUND')} value={prop.SurfaceGround2} numberHtml={true} addText='m&sup2;' />}
                        {<HtmlFormat title={t('FRONT_WIDTH')} value={prop.FrontWidth} numberHtml={true} addText='m' />}
                        {<HtmlFormat title={t('PLOT_WIDTH')} value={prop.PlotWidth} numberHtml={true} addText='m' />}
                        {<HtmlFormat title={t('CONSTRUCTION_YEAR')} value={prop.ConstructionYear} numberHtml={true} />}
                    </div>
                </div>
                {!!prop.DocumentItems && <div className='group documents'>
                    <div className='caption'>{t('DOCUMENTS')}</div>
                    <div className='content'>
                        <div id='Documents'>
                            {prop.DocumentItems.map((doc: any, i: number) => {
                                return (
                                    <div key={i}>
                                        <span className='doc'>
                                            <a href={doc.FileName} target='_blank'>
                                                <img src={this.getDocumentIcon(doc.FileName)} alt='document' />
                                                <span>{doc.Comment}</span>
                                            </a>
                                        </span>
                                    </div>);
                            })}
                        </div>
                    </div>
                </div>}
            </div>
        );
    }
}

export default translate('translations')(PanelRightComponent);
