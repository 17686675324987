import * as React from 'react';
import PropertyGNewComponent from '../PropertyComponent/grid-for-newest/property-g-new';
import { translate } from 'react-i18next';
import * as helper from '../../helper/helpers';
import LazyLoad from 'react-lazyload';
import { debounce } from 'lodash';

interface IPropertyNewComponentProps {
    i18n?: any;
    t?: any;
    propertiesListProps: any[];
    language: string;
    title: string;
}

interface IPropertyNewComponentState {
    // option
    itemsPerRow: number;
    limit: number;
    isMobile: boolean;
}

class PropertyNewComponent extends React.Component<IPropertyNewComponentProps, IPropertyNewComponentState> {
    constructor(props: IPropertyNewComponentProps) {
        super(props);
        this.state = {
            itemsPerRow: 4,
            limit: 4,
            isMobile: false,
        };
    }

    public componentDidMount() {
        if (typeof window !== 'undefined') {
            const { propertiesListProps } = this.props;
            let sessionList = propertiesListProps.filter((item: any) => item.Marquee !== 3 && item.Marquee !== 4);
            sessionList = sessionList.map(item => item.ID);
            helper.setSessionStorage(`propList${propertiesListProps[0].Goal}`, sessionList);
            if (window.innerWidth < 768) {
                this.setState({ isMobile: true });
            }

            window.addEventListener('resize', debounce(() => {// lodash debounce method.

                if (window.innerWidth < 768) {
                    this.setState({ isMobile: true });
                }

            }, 10));
        }
    }

    public render() {
        const { propertiesListProps } = this.props;

        return <div id='PropertyListRegionNew'>
            <div><h2>{this.props.title !== '' ? this.props.title : ''}</h2></div>
            <div className='row-fluid'>
                {propertiesListProps.map((prop, i) => {
                    const classP = 'span' + Math.trunc(12 / 4).toString() + ' property ';
                    if (i > 1 && this.state.isMobile) {
                        return <LazyLoad height={500} offset={100}><div className={classP} key={i}>
                            <PropertyGNewComponent
                                data={prop}
                                stt={i}
                                itemsPerRow={4} /><div className='clearfix'></div>
                        </div></LazyLoad>;
                    } else {
                        return <div className={classP} key={i}>
                            <PropertyGNewComponent
                                data={prop}
                                stt={i}
                                itemsPerRow={4} /><div className='clearfix'></div>
                        </div>;
                    }
                })}
            </div>
            <div className='clear'></div>
        </div>;
    }
}

export default translate('translations')(PropertyNewComponent);
