import * as React from 'react';
import PropertyGComponent from './property-g';
import { debounce , chunk} from 'lodash';

interface IPropertyGridComponentProps {
    ListProperty: any[];
    itemsPerRow: any;
}

interface IPropertyGridComponentState {
    renderList: any;
    itemsPerRow: number;
}

export default class PropertyGridComponent extends React.Component<IPropertyGridComponentProps, IPropertyGridComponentState> {

    constructor(props: IPropertyGridComponentProps) {
        super(props);
        this.state = {
            renderList: [],
            itemsPerRow: 4,
        };
    }

    public componentDidMount() {
        const width = window.innerWidth;
        let itemsPerRow = this.props.itemsPerRow;

        if (width <= 1011 && width >= 847) {
            itemsPerRow = 3;
        } else if (width <= 846 && width >= 768) {
            itemsPerRow = 2;
        } else if (width <= 767) {
            itemsPerRow = 1;
        }

        this.setState({ itemsPerRow });

        window.addEventListener('resize', debounce(() => {// lodash debounce method.

            const width = window.innerWidth;
            let itemsPerRow = this.props.itemsPerRow;

            if (width <= 1011 && width >= 847) {
                itemsPerRow = 3;
            } else if (width <= 846 && width >= 768) {
                itemsPerRow = 2;
            } else if (width <= 767) {
                itemsPerRow = 1;
            }

            this.setState({ itemsPerRow });
        }, 10));
    }

    public render() {
        const renderList = chunk(this.props.ListProperty, this.state.itemsPerRow);
        return (
            <div>
                {renderList.map((arr: any[], i: number) => {
                    return (
                        <div className='row-fluid' key={i}>
                            {arr.map((prop, i) => {
                                const propData = prop;
                                return <PropertyGComponent
                                    key={i}
                                    data={propData}
                                    stt={i}
                                    itemsPerRow={this.props.itemsPerRow}
                                    classProp={`span${Math.trunc(12 / this.state.itemsPerRow).toString()} property `} />;
                            })}
                        </div>
                    );
                })}
            </div>
        );
    }
}
