import * as React from 'react';
import { Link } from 'gatsby';
import '../css/property-grid.css';
import { translate } from 'react-i18next';
import ProgressiveImage from 'react-progressive-image';
import * as helper from '../../../helper/helpers';
import * as FontAwesomeIcon from 'react-fontawesome';

interface IPropertyGComponentProps {
    itemsPerRow: number;
    classProp?: string;
    data: any;
    stt: any;
    i18n?: any;
    t?: any;
}

class PropertyGComponent extends React.Component<IPropertyGComponentProps> {
    constructor(props: IPropertyGComponentProps) {
        super(props);
    }

    public componentDidMount() {
    }

    public componentWillReceiveProps(nextProps: any) {

    }
    public render() {
        const { t, i18n, itemsPerRow } = this.props;
        let { classProp } = this.props;
        if ((this.props.stt % itemsPerRow) === (itemsPerRow - 1)) {
            classProp = classProp + 'last';
        }
        return <div className={classProp}>
            <div className='property-image'>
                {this.props.data.Marquee !== 3 && this.props.data.Marquee !== 4 &&
                    <Link to={'/property/' + this.props.data.ID} aria-label={'property-' + this.props.data.ID}>
                        <ProgressiveImage src={`${this.props.data.ImageUrl}?w=320&h=199&fit=crop&auto=format,compress`} placeholder={`${this.props.data.ImageUrl}?w=27&h=17&q=1&auto=format,compress`} >
                            {(src: any, loading: any) => (
                                <img style={{ opacity: loading ? 0.3 : 1 }} className='coverImage' src={src} alt={`property-${this.props.data.ID}`} />
                            )}
                        </ProgressiveImage>
                        <div className='marquee' style={{ backgroundImage: `url(${helper.getMarqueeImage(this.props.data.Marquee, i18n.language)})` }}></div>
                        <noscript>
                            <img className='coverImage' src={`${this.props.data.ImageUrl}?w=320&h=199&fit=crop&auto=format,compress`} alt={`property-${this.props.data.ID}`} />
                        </noscript>                        {this.props.data.Marquee !== 0 && <div className='marquee' style={{ backgroundImage: `url(${helper.getMarqueeImage(this.props.data.Marquee, i18n.language)})` }}></div>}
                    </Link>}
                {(this.props.data.Marquee === 3 || this.props.data.Marquee === 4) &&
                    <div style={{ width: '100%', height: '100%' }}>
                        <div className='marquee' style={{ backgroundImage: `url(${helper.getMarqueeImage(this.props.data.Marquee, i18n.language)})` }}></div>
                        <ProgressiveImage src={`${this.props.data.ImageUrl}?w=320&h=199&fit=crop&auto=format,compress`} placeholder={`${this.props.data.ImageUrl}?w=27&h=17&q=1&auto=format,compress`} >
                            {(src: any, loading: any) => (
                                <img style={{ opacity: loading ? 0.3 : 1 }} className='coverImage' src={src} alt={`property-${this.props.data.ID}`} />
                            )}
                        </ProgressiveImage>
                        <div className='marquee' style={{ backgroundImage: `url(${helper.getMarqueeImage(this.props.data.Marquee, i18n.language)})` }}></div>
                        <noscript>
                            <img className='coverImage' src={`${this.props.data.ImageUrl}?w=320&h=199&fit=crop&auto=format,compress`} alt={`property-${this.props.data.ID}`} />
                        </noscript>
                    </div>}
            </div>
            <div className='property-info'>
                <div className='prop-type pull-left'>{this.props.data.MainTypeName}</div>
                <div className='prop-price pull-right'>
                    {this.props.data.Marquee !== 3 && this.props.data.Marquee !== 4 && helper.formatCurrency(this.props.data.Price)}
                    {!(this.props.data.Marquee !== 3 && this.props.data.Marquee !== 4) && <div className='prop-price'>{t(`MARQUEE_${this.props.data.Marquee}`)}</div>}
                </div>
                <div className='clearfix'></div>
                <div className='prop-city'>
                    {this.props.data.City}
                </div>
                <div className='icons'>
                    <div className={'fleft ' + (this.props.data.SurfaceTotal === 0 ? 'hidden' : '')} >
                        <FontAwesomeIcon name='home' /> {this.props.data.SurfaceTotal} m&sup2;
                        </div>
                    <div className={'fright ' + (this.props.data.NumberOfGarages === 0 ? 'hidden' : '')}>
                        <FontAwesomeIcon name='car' /> {this.props.data.NumberOfGarages}
                    </div>
                    <div className={'fright ' + (this.props.data.NumberOfBathRooms === 0 ? 'hidden' : '')} >
                        <FontAwesomeIcon name='bath' /> {this.props.data.NumberOfBathRooms}
                    </div>
                    <div className={'fright ' + (this.props.data.NumberOfBedRooms === 0 ? 'hidden' : '')} >
                        <FontAwesomeIcon name='bed' /> {this.props.data.NumberOfBedRooms}
                    </div>
                    <div className='clear'></div>
                </div>
                <div className='desc hidden-tablet'>{this.props.data.DescriptionA.substring(0, 70)}...</div>
                <div className='desc visible-tablet'>{this.props.data.DescriptionA.substring(0, 70)}...</div>

            </div>
            <div className='clearfix'></div>
        </div>;
    }
}

export default translate('translations')(PropertyGComponent);
