import * as React from 'react';
import { translate } from 'react-i18next';
import HtmlFormat from '../html-format/html-format';

interface IShowCaseTemplateProps {
    i18n?: any;
    t?: any; // translate
    data: any;
}

class ShowCaseTemplate extends React.Component<IShowCaseTemplateProps> {
    constructor(props: IShowCaseTemplateProps) {
        super(props);
    }

    public render() {
        const { i18n } = this.props;
        return (
            <div id='showcase-row'>
                {this.props.data.map((item: any, i: number) => {
                    return <HtmlFormat dataContext={item} key={i} language={i18n.language} />;
                })}
            </div>
        );
    }
}

export default translate('translations')(ShowCaseTemplate);
