import * as React from 'react';
import { translate } from 'react-i18next';

interface IEPCProps {
    i18n?: any;
    t?: any;
    prop: any;
}

interface IEPCState {
    EnergyPerformance: string;
    showFlanders: boolean;
    showBrussels: boolean;
    showWallonia: boolean;
    showEPCContainer: boolean;
}

const EPC: any = {
    Data: {
        value: null,
        postCode: -1,
        title: 'EPC waarde',
        left: 87,
        imgPath: 'images',
        top: 14,
        blackImg: false
    },
    brusselsRange: [['-', 0, 'a_plus'], [1, 23, 'a'], [24, 45, 'a_sub'], [46, 62, 'b_plus'], [63, 79, 'b'], [80, 95, 'b_sub'], [96, 113, 'c_plus'], [114, 132, 'c'], [133, 150, 'c_sub'], [151, 170, 'd_plus'], [171, 190, 'd'], [191, 210, 'd_sub'], [211, 232, 'e_plus'], [233, 253, 'e'], [254, 275, 'e_sub'], [276, 298, 'f_plus'], [299, 322, 'f'], [223, 345, 'f_sub'], [346, '+', 'g']],
    walloniaRange: [['-', 0, 'a_plusplus'], [0, 45, 'a_plus'], [45, 85, 'a'], [85, 170, 'b'], [170, 255, 'c'], [255, 340, 'd'], [340, 425, 'e'], [425, 510, 'f'], [510, '+', 'g']],
};

class EPCDetailComponent extends React.Component<IEPCProps, IEPCState> {
    constructor(props: IEPCProps) {
        super(props);
        this.state = {
            EnergyPerformance: '',
            showFlanders: false,
            showBrussels: false,
            showWallonia: false,
            showEPCContainer: true
        };
    }

    public componentDidMount() {
        const { t, prop } = this.props;
        this.setImageEPC(prop, t);
    }

    public async componentWillReceiveProps(nextProps: any) {
        const { t, prop } = nextProps;
        this.setImageEPC(prop, t);
    }

    public async setImageEPC(prop: any, t: any) {
        await this.setState({ EnergyPerformance: prop.EnergyPerformance });
        this.show({
            imgPath: 'https://epclabel.omnicasa.com/images',
            value: parseInt(this.state.EnergyPerformance, 10),
            postCode: parseInt(prop.Zip, 10),
            title: t('EPC'),
            blackImg: true,
            left: 0,
            top: 1
        });

        let epc_val = parseInt(this.state.EnergyPerformance, 10);
        if (epc_val > 700) {
            epc_val = 700;
        }
        const percent = ((epc_val / 700) * 0.91) * 100 + 6.15;
        const elFlanders = document.getElementById('Flanders');
        if (typeof elFlanders !== 'undefined' && elFlanders !== null) {
            elFlanders.getElementsByClassName('EPC_Point')[0].setAttribute('style', 'left: ' + percent + '%');
        }
    }

    public show(inputData: any) {
        if (inputData !== null) {
            if (inputData.value !== null) {
                EPC.Data.value = inputData.value;
            }
            if (inputData.title !== null) {
                EPC.Data.title = inputData.title;
            }
            if (inputData.left !== null) {
                EPC.Data.left = inputData.left;
            }
            if (inputData.postCode !== null && !isNaN(inputData.postCode) && inputData.postCode > 0) {
                EPC.Data.postCode = inputData.postCode;
            }
            if (inputData.top !== null) {
                EPC.Data.top = inputData.top;
            }
            if (inputData.imgPath !== null) {
                EPC.Data.imgPath = inputData.imgPath;
            }
            if (inputData.blackImg !== null) {
                EPC.Data.blackImg = inputData.blackImg;
            }

            try {
                document.getElementsByClassName('EPC_Label')[0].innerHTML = EPC.Data.title;
                document.getElementsByClassName('EPC_Label')[0].setAttribute('style', 'width:' + EPC.Data.left);
                document.getElementsByClassName('EPC_Label')[0].setAttribute('style', 'top:' + EPC.Data.top);
                switch (this.getRegionType(EPC.Data.postCode)) {
                    case 'Flanders':
                        this.setState({ showFlanders: true });
                        const elFlanders = document.getElementById('Flanders');
                        if (typeof elFlanders !== 'undefined' && elFlanders !== null) {
                            elFlanders.getElementsByClassName('EPC_Label')[0].setAttribute('style', 'top: ' + EPC.Data.top + 14);
                        }
                        this.Flanders(EPC.Data.value, EPC.Data.left);
                        break;
                    case 'Brussels':
                        if (EPC.Data.value !== null) {
                            this.setState({ showBrussels: true });
                            const el_img_Brussel = document.getElementById('img_Brussel');
                            if (typeof el_img_Brussel !== 'undefined' && el_img_Brussel !== null) {
                                el_img_Brussel.getElementsByClassName('EPC_Label')[0].setAttribute('src', EPC.Data.imgPath + '/brussels/' + EPC.getBrusselFileName(EPC.Data.value) + '.png');
                            }
                        }
                        break;
                    case 'Wallonia':
                        if (EPC.Data.value !== null) {
                            this.setState({ showWallonia: true });
                            const el_img_Wallonia = document.getElementById('img_Wallonia');
                            if (typeof el_img_Wallonia !== 'undefined' && el_img_Wallonia !== null) {
                                el_img_Wallonia.getElementsByClassName('EPC_Label')[0].setAttribute('src', EPC.Data.imgPath + '/wallonia/' + EPC.getWalloniaFileName(EPC.Data.value) + '.png');
                            }
                        }
                        break;
                }
            } catch (err) {
                console.log('Error EPC show: ' + err);
            }
        }
    }

    public getBrusselFileName(value: any) {
        let item = '';
        for (let i = 0; i < EPC.brusselsRange.length; i++) {
            item = EPC.brusselsRange[i];
            if (item[0] === '-' && item[1] >= value) {
                return item[2];
            } else if (item[0] <= value && item[1] === '+') {
                return item[2];
            } else if (item[0] <= value && item[1] >= value) {
                return item[2];
            }
        }
        return '';
    }

    public getWalloniaFileName(value: any) {
        let item = '';
        for (let i = 0; i < EPC.walloniaRange.length; i++) {
            item = EPC.walloniaRange[i];
            if (item[0] === '-' && item[1] >= value) {
                return item[2];
            } else if (item[0] < value && item[1] === '+') {
                return item[2];
            } else if (item[0] < value && item[1] >= value) {
                return item[2];
            }
        }
        return '';
    }

    public getRegionType(postCode: number) {
        if (postCode >= 1000 && postCode <= 1299) {
            return 'Brussels';
        } else if ((postCode >= 1300 && postCode <= 1499) || (postCode >= 4000 && postCode <= 7999)) {
            return 'Wallonia';
        } else if ((postCode >= 1500 && postCode <= 3999) || (postCode >= 8000 && postCode <= 9999)) {
            return 'Flanders';
        }
        return '';
    }

    public Flanders(epc: any, left: number) {
        const ruleW = 533;
        const ruleRealW = 489;
        const ruleLength = 700;
        let epcTemp = epc;

        if (!isNaN(epc) && epc > 0) {
            if (epcTemp > 700) { epcTemp = 700; }
            const left1 = (ruleW - ruleRealW - 5) + left;
            const pos = ((epcTemp * ruleRealW) / ruleLength) + left1;

            document.getElementsByClassName('EPC_Point')[0].setAttribute('style', 'left: ' + pos.toString());

            const el_lblEpc = document.getElementById('lblEPC');
            if (typeof el_lblEpc !== 'undefined' && el_lblEpc !== null) {
                el_lblEpc.innerHTML = epc;
            }

            const el_img_Point = document.getElementById('img_Point');
            if (typeof el_img_Point !== 'undefined' && el_img_Point !== null) {
                el_img_Point.setAttribute('src', EPC.Data.imgPath + '/flanders/epc_values.png');
            }

            document.getElementsByClassName('EPC_Gradient')[0].setAttribute('src', 'left: ' + left.toString());

            if (EPC.Data.blackImg) {
                const el_img_Rule = document.getElementById('img_Rule');
                if (typeof el_img_Rule !== 'undefined' && el_img_Rule !== null) {
                    el_img_Rule.setAttribute('src', EPC.Data.imgPath + '/flanders/epc_gradient_black.png');
                }

            } else {
                const el_img_Rule = document.getElementById('img_Rule');
                if (typeof el_img_Rule !== 'undefined' && el_img_Rule !== null) {
                    el_img_Rule.setAttribute('src', EPC.Data.imgPath + '/flanders/epc_gradient.png');
                }

            }
        } else {
            this.setState({ showEPCContainer: false });
        }
    }

    public render() {
        return (
            <div>
                {this.state.EnergyPerformance && <div id='div_epc'>
                    <div id='Flanders' style={{ display: `${this.state.showFlanders ? 'block' : ' none'}` }}>
                        <div className={`EPC_Container ${this.state.showEPCContainer ? '' : 'hidden'}`}>
                            <div className='EPC_Gradient' >
                                <img id='img_Rule' src=''
                                    style={{
                                        width: '538px',
                                        height: '20px',
                                        border: 0
                                    }} alt='' /><br />
                            </div>
                            <div className='EPC_Point'>
                                <b><label id='lblEPC' style={{ width: '35px' }}></label></b>
                                <img id='img_Point' src='' style={{
                                    width: '12px',
                                    height: '12px',
                                    border: 0
                                }} alt='' />
                            </div>
                            <div className='EPC_Label'></div>
                        </div>
                    </div>
                    <div id='Brussels' style={{ display: `${this.state.showBrussels ? 'block' : ' none'}` }}>
                        <div className='EPC_Label'></div><img id='img_Brussel' src='' style={{ border: 0 }} alt='' />
                    </div>
                    <div id='Wallonia' style={{ display: `${this.state.showWallonia ? 'block' : ' none'}` }}>
                        <div className='EPC_Label'></div><img id='img_Wallonia' src='' style={{ border: 0 }} alt='' />
                    </div>
                </div>}
            </div>
        );
    }
}

export default translate('translations')(EPCDetailComponent);
