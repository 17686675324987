import * as React from 'react';
import { translate } from 'react-i18next';
import EPC from './epc/epc';
import Maps from './Maps-detail';
import StreetView from './streetview-detail';
import HtmlFormat from './general-detail/htmlFormat';
import Divisions from './devisions';
import floodicon from '../../../layouts/images/floodicon.png';
interface IPanelContentProps {
    i18n?: any;
    t?: any;
    prop: any;
}

class PanelContentComponent extends React.Component<IPanelContentProps> {
    constructor(props: IPanelContentProps) {
        super(props);
    }

    public render() {
        const { t, prop } = this.props;
        return (
            <div className='group-container span12'>
                <div className='row-fluid'>
                    <div className='span8'>
                        <div className='row-fluid'>
                            <div className='group'>
                                <div className='caption'>{t('DESCRIPTION')}</div>
                                <div className='content' style={{ textAlign: 'justify' }}>
                                    <div className='field'>{prop.DescriptionA}</div>
                                    <br />
                                    <EPC prop={prop} />
                                </div>
                            </div>
                        </div>
                        <div className='row-fluid'>
                            <div className='group span6'>
                                <div className='caption'>{t('PLANNING_INFORMATION')}</div>
                                <div className='content'>
                                    {prop.Goal === 0 && <HtmlFormat title={t('HAS_PREEMPTIVE_RIGHTS')} value={prop.PreemptiveRightsText} stringHtml={true} />}
                                    {prop.Goal === 0 && <HtmlFormat title={t('HAS_PERMIT')} value={prop.PermitText} stringHtml={true} />}
                                    {prop.Goal === 0 && <HtmlFormat title={t('HAS_SUMMONS')} value={prop.SummonsText} stringHtml={true} />}
                                    {prop.Goal === 0 && <HtmlFormat title={t('HAS_ALLOTMENT_PERMIT')} value={prop.AllotmentpermitText} stringHtml={true} />}
                                    {prop.Goal === 0 && <HtmlFormat title={t('JUDICIAL_DECISION')} value={prop.JudicialDecisionText} stringHtml={true} />}
                                    {prop.Goal === 0 && <HtmlFormat title={t('AREA_APPLICATION')} value={prop.AreaApplicationName} stringHtml={true} />}
                                    {<HtmlFormat title={t('CO2_EMISSION')} value={prop.CO2Emission} numberHtml={true} addText='CO2/m²/jaar' />}
                                    {<HtmlFormat title={t('EPC')} value={prop.EnergyPerformance} numberHtml={true} addText='kWh/m&sup2' />}
                                    {<HtmlFormat title={t('EPCTOTAL')} value={prop.EPCTotal} numberHtml={true} addText='kWh/jaar' />}
                                    {<HtmlFormat title={t('UNIQUE_CODE')} value={prop.UniqueCode} stringHtml={true} />}

                                    {prop.IsFloodriskIcon !== true &&
                                        <div className='field'>
                                            <div className='name' style={{ fontWeight: 'normal' }}>{prop.FloodriskName}</div>
                                            <div className='clear' ></div>
                                        </div>}
                                    {prop.IsFloodriskIcon !== false &&
                                        <div className='field'>
                                            <div className='name' style={{ lineHeight: '25px', fontWeight: 'normal' }}>
                                                <div style={{ float: 'left', width: '25px', marginRight: '5px' }}>
                                                    <img src={floodicon} style={{ border: 0, width: '25px' }} alt='floodicon' /></div>{prop.FloodriskName}</div>
                                            <div className='clear' ></div>
                                        </div>}
                                </div>
                            </div>
                            <div className='group span6' >
                                <div className='caption'>{t('CONFORT')}</div>
                                <div className='content'>
                                    {<HtmlFormat title={t('HEATING_NAME')} value={prop.HeatingBName} stringHtml={true} />}
                                    {<HtmlFormat title={t('HAS_GAS')} value={!!prop.HasGas ? prop.HasGas : t('NOT_SET')} booleanHtml={true} />}
                                    {<HtmlFormat title={t('HAS_WATER')} value={!!prop.HasWater ? prop.HasWater : t('NOT_SET')} booleanHtml={true} />}
                                    {<HtmlFormat title={t('HAS_LIFT')} value={!!prop.HasLift ? prop.HasLift : t('NOT_SET')} booleanHtml={true} />}
                                    {<HtmlFormat title={t('INTERIOR_NAME')} value={prop.InteriorName} stringHtml={true} />}
                                    {<HtmlFormat title={t('KITCHEN_NAME')} value={prop.KitchenName} stringHtml={true} />}
                                    {<HtmlFormat title={t('EXTOERIOR_NAME')} value={prop.ExteriorName} stringHtml={true} />}
                                    {<HtmlFormat title={t('WINDOW_GLAZING')} value={prop.WindowGlazing} stringHtml={true} />}
                                    {<HtmlFormat title={t('WINDOW_FRAME')} value={prop.WindowFrame} stringHtml={true} />}
                                </div>
                            </div>
                        </div>
                        {!!prop.Divisions && prop.Divisions.length > 0 &&
                            <div className='row-fluid group divisions'>
                                <div className='span12'>
                                    <div className='caption'>{t('DIVISIONS')}</div>
                                    <div className='row-fluid'>
                                        <Divisions prop={prop} />
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    <div className='span4 maps'>
                        {prop.PublishAddress && parseFloat(prop.GoogleX) > 0 && parseFloat(prop.GoogleY) > 0 &&
                            <Maps prop={prop} height='200px' />}
                        {prop.PublishAddress && parseFloat(prop.GoogleX) > 0 && parseFloat(prop.GoogleY) > 0 &&
                            <StreetView prop={prop} />}
                    </div>
                </div>
                <div className='row-fluid'>

                </div>
            </div>
        );
    }
}

export default translate('translations')(PanelContentComponent);
