import * as React from 'react';
import * as ReactDOMServer from 'react-dom/server';
import { translate } from 'react-i18next';
import { navigate } from 'gatsby';
import * as formHelper from './form-helper/form-helper';
import * as helper from '../../helper/helpers';
import * as Recaptcha from 'react-recaptcha';
import ContactMail from './mail_templates/mail-teamplte';
import Loading from '../GeneralComponent/background-loading';
import FeedBackPopup from './feedback-popup';

const _dataJson = require('../../../content/contact/send-to-friend/send-to-friend.json');

interface IContactFormProps {
    i18n?: any;
    t?: any;
    isPopup: boolean;
}

interface IContactFormState {
    txtLastName: string;
    txtFirstName: string;
    txtEmail: string;
    txtEmailFriend: string;
    txtRemark: string;
    reCaptchaResponse: string;
    useCaptcha: boolean;
    subject: string;
    mail_key: string;
    website: string;
    linkProperty: string;
    send_success: boolean;
    mailSendStatus: string;
    formValidateEmpty: any[];
}

class SiteContactFormComponent extends React.Component<IContactFormProps,IContactFormState> {
    private recaptchaInstance  = React.createRef<{}>();
    constructor(props: IContactFormProps) {
        super(props);
        const { t } = props;
        this.state = {
            website: '',
            subject: t('MAIL_SUBJECT_SEND_TO_FRIEND'),
            mail_key: 'SEND_TO_FRIEND',
            useCaptcha: true,
            formValidateEmpty: ['txtLastName', 'txtFirstName', 'txtEmail', 'txtEmailFriend'],
            txtLastName: '',
            txtFirstName: '',
            txtEmail: '',
            txtEmailFriend: '',
            txtRemark: '',
            reCaptchaResponse: '',
            linkProperty: '',
            send_success: false,
            mailSendStatus: ''
        };
    }

    public componentDidMount() {
        this.setState({ website: window.location.origin, linkProperty: window.location.href });
    }

    public componentWillReceiveProps(nextProps: any) {

    }

    public handleUserInput(e: any, validation: string[]) {
        const name = e.target.id;
        const value = e.target.value;
        let result: any = false;
        if (validation.length > 0) {
            result = formHelper.validation(name, value, validation);
            formHelper.validateException(name, result);
            if (result === false) {
                // @ts-ignore
                this.setState({ [name]: value });
            } else {
                // @ts-ignore
                this.setState({ [name]: '' });
            }
        } else {
            // @ts-ignore
            this.setState({ [name]: value });
        }
    }

    public async onSubmit(event: any) {
        event.preventDefault();
        const validate = formHelper.formValidate(this.state);
        if (validate === false) {
            await this.setState({mailSendStatus: 'sending'});
            if(this.state.useCaptcha) {
                // @ts-ignore
                this.recaptchaInstance.execute();
                const check_resCaptcha = setInterval(async () => {
                    if(this.state.reCaptchaResponse !== '' && this.state.reCaptchaResponse !== null) {
                        // const jsonData = JSON.stringify(this.state);
                        clearInterval(check_resCaptcha);
                        const objectToSend = this.prepareDataToSend();
                        const res: any = await formHelper.sendMail(objectToSend, 'send-to-friend');
                        if(res.status === 200 ) {
                            await this.setState({ mailSendStatus: 'sent', send_success: true });
                            if(this.props.isPopup === false) {
                                navigate(`/feed-back?success=1&f=${this.state.mail_key.toLowerCase()}`);
                            }
                        } else {
                            await this.setState({ mailSendStatus: 'sent', send_success: false });
                            if(this.props.isPopup === false) {
                                navigate(`/feed-back?success=0&f=${this.state.mail_key.toLowerCase()}`);
                            }
                        }
                    }
                }, 1000);
            } else {
                const objectToSend = this.prepareDataToSend();
                const res: any = await formHelper.sendMail(objectToSend, 'send-to-friend');
                if(res.status === 200 ) {
                    await this.setState({ mailSendStatus: 'sent', send_success: true });
                    if(this.props.isPopup === false) {
                        navigate(`/feed-back?success=1&f=${this.state.mail_key.toLowerCase()}`);
                    }
                } else {
                    await this.setState({ mailSendStatus: 'sent', send_success: false });
                    if(this.props.isPopup === false) {
                        navigate(`/feed-back?success=0&f=${this.state.mail_key.toLowerCase()}`);
                    }
                }
            }
        }
    }

    public onLoadRecaptcha() {
        console.log('reCaptcha ready !!!');
    }

    public verifyCallbackCaptcha(response: any) {   
        this.setState({ reCaptchaResponse: response });
    }

    public expiredCallbackCaptcha() {
        alert('reCaptcha error !!!');
    }

    public prepareDataToSend() {
        const data = {
            ContactData: {
                Name: this.state.txtLastName,
                FirstName: this.state.txtFirstName,
                Content: this.state.txtRemark,
                Subject: this.state.subject,
                EmailFriend: this.state.txtEmailFriend,
                Email: this.state.txtEmail,
            },
            headers: {
                ReCaptchaResponse: this.state.reCaptchaResponse,
                UseCaptcha: this.state.useCaptcha,
            },
            SendMail: {
                HtmlBody: ReactDOMServer.renderToString(<ContactMail type='send-to-friend' data={this.state}/>)
            }
        };
        return data;
    }

    public render() {
        const { t, i18n } = this.props;
        const dataContact = _dataJson;
        return (
            <div className='site-contact'>
                {this.state.mailSendStatus === 'sent' && this.props.isPopup === true &&  <FeedBackPopup success={this.state.send_success} contactName={this.state.mail_key.toLowerCase()} />}
                <Loading show={this.state.mailSendStatus === 'sending'? true : false} />
                {(this.state.mailSendStatus === '' || this.state.mailSendStatus === 'sending') && <div id='ContactRegion'>     
                    <h2>{helper.getContentWithLanguage(dataContact.titlePage, i18n.language)}</h2>
                    <div className='contact-desc' style={{whiteSpace: 'pre-wrap'}}  dangerouslySetInnerHTML={{ __html: helper.getContentWithLanguage(dataContact.body, i18n.language)}}></div>
                    <br />
                    <legend><h4>{t('SEND_TO_FRIEND')}</h4></legend>
                    <form>
                        <div className='row-fluid'>
                            <div className='row-fluid'>
                                <div className='span6'>
                                    <div className='control-group'>
                                        <label area-label='txtFirstName' className='control-label'>{t('FIRST_NAME_FRIEND')} <span className='required'>*</span> :</label>
                                        <div className='controls'><input type='text' id='txtFirstName' name='FIELD[FirstName]' onChange={event => this.handleUserInput(event, ['isEmpty'])}  /></div>
                                    </div>
                                </div>
                                <div className='span6'><div className='control-group'>
                                    <label area-label='txtLastName' className='control-label'>{t('LAST_NAME_FRIEND')} <span className='required'>*</span> :</label>
                                    <div className='controls'><input type='text' id='txtLastName' name='FIELD[Name]' onChange={event => this.handleUserInput(event, ['isEmpty'])} /></div>
                                </div></div>
                            </div>
                            <div className='row-fluid'>
                                <div className='span12'><div className='control-group'>
                                    <label area-label='txtEmail' className='control-label'>{t('EMAIL')} <span className='required'>*</span> :</label>
                                    <div className='controls'><input type='text' id='txtEmail' name='FIELD[Email]' onChange={event => this.handleUserInput(event, ['isEmpty' ,'isEmail'])}  /></div>
                                </div></div>
                            </div>
                            <div className='row-fluid'>
                                <div className='span12'><div className='control-group'>
                                    <label area-label='txtRemark' className='control-label'>{t('REMARK')}  :</label>
                                    <div className='controls'><textarea id='txtRemark' name='FIELD[Content]' onChange={event => this.handleUserInput(event, [])} ></textarea></div>
                                </div></div>
                            </div>
                            <div className='row-fluid'>
                                <div className='span12'><div className='control-group'>
                                    <label area-label='txtHouseNumber' className='control-label'>{t('EMAIL_RECEIVER')}  <span className='required'>*</span> :</label>
                                    <div className='controls'><input type='text' id='txtEmailFriend' name='FIELD[EmailFriend]' onChange={event => this.handleUserInput(event, ['isEmpty', 'isEmail'])} /></div>
                                </div></div>
                            </div>
                            <div className='row-fluid'>
                                <div className='span12'>
                                    {this.state.useCaptcha && <div style={{width: '50%',margin: '0 auto', marginBottom: '30px'}}>
                                    <Recaptcha
                                        ref={(c: any) => { this.recaptchaInstance = c; }}
                                        sitekey='6LfnXnIUAAAAAN_2K5ZPuzi6JvamtdrV6oOSIWfN'
                                        render='explicit'
                                        badge='bottomright'
                                        size='invisible'
                                        hl={i18n.language}
                                        onloadCallback={this.onLoadRecaptcha.bind(this)}
                                        verifyCallback={this.verifyCallbackCaptcha.bind(this)}
                                        expiredCallbackName={this.expiredCallbackCaptcha.bind(this)}
                                    />
                                    </div>}
                                </div>
                            </div>
                        </div>
                    <div className='clearfix'></div>
                    <div className='buttons'>
                        <button type='button' className='btn' aria-label='send form' onClick={this.onSubmit.bind(this)} >{t('SUBMIT')} <i className='icon-black icon-ok-sign'></i></button>
                    </div>
                </form>
            </div>}
        </div>
        );
    }
}

export default translate('translations')(SiteContactFormComponent);
