import * as React from 'react';
import * as ReactDOMServer from 'react-dom/server';
import { translate } from 'react-i18next';
import { Link, navigate } from 'gatsby';
import * as formHelper from './form-helper/form-helper';
import * as helper from '../../helper/helpers';
import * as Recaptcha from 'react-recaptcha';
import ContactMail from './mail_templates/mail-teamplte';
import Loading from '../GeneralComponent/background-loading';
import FeedBackPopup from './feedback-popup';

const _dataJson = require('../../../content/contact/contact-me/contact-me.json');

interface IContactFormProps {
    i18n?: any;
    t?: any;
    data?: any;
    contactName: string;
    isPopup: boolean;
}

interface IContactFormState {
    selectedTitle: string;
    txtLastName: string;
    txtFirstName: string;
    txtPhone: string;
    txtStreet: string;
    txtHouseNumber: string;
    txtPostCode: string;
    txtCity: string;
    txtEmail: string;
    txtRemark: string;
    blnNewsLetter: boolean;
    reCaptchaResponse: string;
    useCaptcha: boolean;
    subject: string;
    mail_key: string;
    website: string;

    objectID: string;
    officeID: string;
    propertyGoal: string;
    propertyType: string;
    propertyHouseNumber: string;
    propertyStreet: string;
    propertyCity: string;
    propertyZip: string;
    send_success: boolean;
    mailSendStatus: string;
    formValidateEmpty: any[];
}

class ContactFormComponent extends React.Component<IContactFormProps, IContactFormState> {
    private recaptchaInstance = React.createRef<{}>();
    constructor(props: IContactFormProps) {
        super(props);
        const { t, data } = props;
        this.state = {
            website: '',
            subject: t('MAIL_SUBJECT_CONTACT_ME'),
            mail_key: 'CONTACT_ME',
            useCaptcha: true,
            selectedTitle: t('MR'),
            formValidateEmpty: ['txtLastName', 'txtFirstName', 'txtPhone', 'txtEmail'],
            txtLastName: '',
            txtFirstName: '',
            txtPhone: '',
            txtStreet: '',
            txtHouseNumber: '',
            txtPostCode: '',
            txtCity: '',
            txtEmail: '',
            txtRemark: '',
            blnNewsLetter: false,
            reCaptchaResponse: '',
            objectID: !!data.ID ? data.ID : '',
            officeID: !!data.OfficeID ? data.OfficeID : '',
            propertyGoal: t(helper.getNameGoal(this.props.data.Goal)),
            propertyType: !!data.WebIDName ? data.WebIDName : '',
            propertyHouseNumber: !!data.HouseNumber ? data.HouseNumber : '',
            propertyStreet: !!data.Street ? data.Street : '',
            propertyCity: !!data.City ? data.City : '',
            propertyZip: !!data.Zip ? data.Zip : '',
            send_success: false,
            mailSendStatus: '',
        };
    }

    public componentDidMount() {
        const { t } = this.props;
        const subject = !!this.props.contactName ? this.props.contactName : 'MAIL_SUBJECT_CONTACT_ME';
        this.setState({ subject: t(subject), website: window.location.origin, propertyGoal: t(helper.getNameGoal(this.props.data.Goal)) });
    }

    public componentWillReceiveProps(nextProps: any) {

    }

    public handleUserInput(e: any, validation: string[]) {
        const name = e.target.id;
        const value = e.target.value;
        let result: any = false;
        if (validation.length > 0) {
            result = formHelper.validation(name, value, validation);
            formHelper.validateException(name, result);
            if (result === false) {
                // @ts-ignore
                this.setState({ [name]: value });
            } else {
                // @ts-ignore
                this.setState({ [name]: '' });
            }
        } else {
            // @ts-ignore
            this.setState({ [name]: value });
        }
    }

    public handleblnNewsLetter(e: any) {
        this.setState({ blnNewsLetter: !this.state.blnNewsLetter });
    }

    public async onSubmit(event: any) {
        event.preventDefault();
        const validate = formHelper.formValidate(this.state);
        if (validate === false) {
            if (this.state.useCaptcha) {
                this.setState({ mailSendStatus: 'sending' });
                // @ts-ignore
                this.recaptchaInstance.execute();
                const check_resCaptcha = setInterval(async () => {
                    if (this.state.reCaptchaResponse !== '' && this.state.reCaptchaResponse !== null) {
                        // const jsonData = JSON.stringify(this.state);
                        clearInterval(check_resCaptcha);
                        const objectToSend = this.prepareDataToSend();
                        const res: any = await formHelper.sendMail(objectToSend, 'contact-me');
                        if (res.status === 200) {
                            await this.setState({ mailSendStatus: 'sent', send_success: true });
                            if (this.props.isPopup === false) {
                                navigate(`/feed-back?success=1&f=${this.state.mail_key.toLowerCase()}`);
                            }
                        } else {
                            await this.setState({ mailSendStatus: 'sent', send_success: false });
                            if (this.props.isPopup === false) {
                                navigate(`/feed-back?success=0&f=${this.state.mail_key.toLowerCase()}`);
                            }
                        }
                    }
                }, 1000);
            } else {
                const objectToSend = this.prepareDataToSend();
                const res: any = await formHelper.sendMail(objectToSend, 'contact-me');
                if (res.status === 200) {
                    await this.setState({ mailSendStatus: 'sent', send_success: true });
                    if (this.props.isPopup === false) {
                        navigate(`/feed-back?success=1&f=${this.state.mail_key.toLowerCase()}`);
                    }
                } else {
                    await this.setState({ mailSendStatus: 'sent', send_success: false });
                    if (this.props.isPopup === false) {
                        navigate(`/feed-back?success=0&f=${this.state.mail_key.toLowerCase()}`);
                    }
                }
            }
        }
    }

    public onLoadRecaptcha() {
        console.log('reCaptcha ready !!!');
    }

    public verifyCallbackCaptcha(response: any) {
        this.setState({ reCaptchaResponse: response });
    }

    public prepareDataToSend() {
        const data = {
            ContactData: {
                Name: this.state.txtLastName,
                FirstName: this.state.txtFirstName,
                AddressTitle: this.state.selectedTitle,
                City: this.state.txtCity,
                Zip: this.state.txtPostCode,
                Email: this.state.txtEmail,
                PhoneNumber: this.state.txtPhone,
                Content: this.state.txtRemark,
                Subject: this.state.subject,
                IsReceivedEmail: this.state.blnNewsLetter,
                ObjectID: this.state.objectID,
                OfficeID: this.state.officeID,
            },
            headers: {
                ReCaptchaResponse: this.state.reCaptchaResponse,
                UseCaptcha: this.state.useCaptcha,
            },
            SendMail: {
                HtmlBody: ReactDOMServer.renderToString(<ContactMail type='contact-me' data={this.state} />)
            }
        };
        return data;
    }

    public render() {
        const { t, i18n } = this.props;
        const dataContact = _dataJson;
        return (
            <div className='site-contact'>
                {this.state.mailSendStatus === 'sent' && this.props.isPopup === true && <FeedBackPopup success={this.state.send_success} contactName={this.state.mail_key.toLowerCase()} />}
                <Loading show={this.state.mailSendStatus === 'sending' ? true : false} />
                {(this.state.mailSendStatus === '' || this.state.mailSendStatus === 'sending') && <div id='ContactRegion'>
                    <h2>{helper.getContentWithLanguage(dataContact.titlePage, i18n.language)}</h2>
                    <div className='contact-desc' style={{ whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={{ __html: helper.getContentWithLanguage(dataContact.body, i18n.language) }}></div>
                    <br />
                    <legend><h4>{t('YOUR_EMAIL_AND_ADDRESS_FORM')}</h4></legend>
                    <form>
                        <div className='row-fluid'>
                            <div className='row-fluid'>
                                <div className='span4'>
                                    <div className='control-group'>
                                        <label area-label='selTitle' className='control-label'>{t('TITLE')} <span className='required'>*</span> :</label>
                                        <div className='controls'>
                                            <select
                                                id='selectedTitle'
                                                name='FIELD[AddressTitle]'
                                                value={this.state.selectedTitle}
                                                onChange={event => this.handleUserInput(event, ['isEmpty'])}>
                                                <option value={t('MR')}>{t('MR')}</option>
                                                <option value={t('MS')}>{t('MS')}</option>
                                                <option value={t('MISS')}>{t('MISS')}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className='span4'>
                                    <div className='control-group'>
                                        <label area-label='txtFirstName' className='control-label'>{t('FIRST_NAME')} <span className='required'>*</span> :</label>
                                        <div className='controls'><input type='text' id='txtFirstName' name='FIELD[FirstName]' onChange={event => this.handleUserInput(event, ['isEmpty'])} /></div>
                                    </div>
                                </div>
                                <div className='span4'><div className='control-group'>
                                    <label area-label='txtLastName' className='control-label'>{t('LAST_NAME')} <span className='required'>*</span> :</label>
                                    <div className='controls'><input type='text' id='txtLastName' name='FIELD[Name]' onChange={event => this.handleUserInput(event, ['isEmpty'])} /></div>
                                </div></div>
                            </div>
                            <div className='row-fluid'>
                                <div className='span4'><div className='control-group'>
                                    <label area-label='txtPhone' className='control-label'>{t('PHONE_NUMBER')} <span className='required'>*</span> :</label>
                                    <div className='controls'><input type='text' id='txtPhone' name='FIELD[PhoneNumber]' onChange={event => this.handleUserInput(event, ['isEmpty', 'isNumber'])} /></div>
                                </div></div>
                                <div className='span4'><div className='control-group'>
                                    <label area-label='txtEmail' className='control-label'>{t('EMAIL')} <span className='required'>*</span> :</label>
                                    <div className='controls'><input type='text' id='txtEmail' name='FIELD[Email]' onChange={event => this.handleUserInput(event, ['isEmpty', 'isEmail'])} /></div>
                                </div></div>
                                <div className='span4'><div className='control-group'>
                                    <label area-label='txtPostCode' className='control-label'>{t('ZIP')}  :</label>
                                    <div className='controls'><input type='text' id='txtPostCode' name='FIELD[Zip]' onChange={event => this.handleUserInput(event, ['isNumber'])} /></div>
                                </div></div>
                            </div>
                            <div className='row-fluid'>
                                <div className='span4'><div className='control-group'>
                                    <label area-label='txtHouseNumber' className='control-label'>{t('HOUSE_NUMBER')}  :</label>
                                    <div className='controls'><input type='text' id='txtHouseNumber' name='FIELD[HouseNumber]' onChange={event => this.handleUserInput(event, [])} /></div>
                                </div></div>
                                <div className='span4'><div className='control-group'>
                                    <label area-label='txtStreet' className='control-label'>{t('STREET')}  :</label>
                                    <div className='controls'><input type='text' id='txtStreet' name='FIELD[Street]' onChange={event => this.handleUserInput(event, [])} /></div>
                                </div></div>
                                <div className='span4'><div className='control-group'>
                                    <label area-label='txtCity' className='control-label'>{t('CITY')}  :</label>
                                    <div className='controls'><input type='text' id='txtCity' name='FIELD[City]' onChange={event => this.handleUserInput(event, [])} /></div>
                                </div></div>
                            </div>
                            <div className='row-fluid'>
                                <div className='span12'><div className='control-group'>
                                    <label area-label='txtRemark' className='control-label'>{t('REMARK')}  :</label>
                                    <div className='controls'><textarea id='txtRemark' name='FIELD[Content]' onChange={event => this.handleUserInput(event, [])} ></textarea></div>
                                </div></div>
                            </div>
                            <div className='row-fluid'>
                                <div className='span12'>
                                    <label><input id='blnNewsLetter' name='FIELD[IsReceivedEmail]' type='checkbox' /> {t('NEWS_LETTER')}</label>
                                </div>
                            </div>
                            <div className='row-fluid'>
                                <div className='span12'>
                                    {t('PRIVACY_STATEMENT_TEXT')} <Link to='/'> {t('PRIVACY_STATEMENT')}</Link>.
                                </div>
                            </div>
                            <div className='row-fluid'>
                                <div className='span12'>
                                    {this.state.useCaptcha && <div style={{ width: '50%', margin: '0 auto', marginBottom: '30px' }}>
                                        <Recaptcha
                                            ref={(c: any) => { this.recaptchaInstance = c; }}
                                            sitekey='6LfnXnIUAAAAAN_2K5ZPuzi6JvamtdrV6oOSIWfN'
                                            render='explicit'
                                            badge='bottomright'
                                            size='invisible'
                                            hl={i18n.language}
                                            onloadCallback={this.onLoadRecaptcha.bind(this)}
                                            verifyCallback={this.verifyCallbackCaptcha.bind(this)}
                                        />
                                    </div>}
                                </div>
                            </div>
                        </div>
                        <div className='clearfix'></div>
                        <div className='buttons'>
                            <button type='button' className='btn' aria-label='send form' onClick={this.onSubmit.bind(this)} >{t('SUBMIT')} <i className='icon-black icon-ok-sign'></i></button>
                        </div>
                    </form>
                </div>}
            </div>
        );
    }
}

export default translate('translations')(ContactFormComponent);
