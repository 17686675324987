import * as React from 'react';
// import DetailOMaps from '../../MapsComponent/detail-maps/omnicasa-maps';
import { translate } from 'react-i18next';
import Modal from '../../ModalComponent/modal';
import * as helper from '../../../helper/helpers';

interface IMapsDetailProps {
    i18n?: any;
    t?: any;
    prop: any;
    height: string;
}

interface IMapsDetailState {
    data: {
        lat: number;
        lng: number
    };
    modalIsOpen: boolean;
}

class MapsDetailComponent extends React.Component<IMapsDetailProps, IMapsDetailState> {
    constructor(props: IMapsDetailProps) {
        super(props);
        this.state = {
            data: {
                lat: 51.018611,
                lng: 4.785,
            },
            modalIsOpen: false,
        };
    }

    public componentDidMount() {

    }

    public openModalMaps() {
        this.setState({modalIsOpen: true});
    }

    public closeModalMaps(value: boolean) {
        this.setState({modalIsOpen: value});
    }

    public postMessageToIframe() {
        const { prop } = this.props;
        helper.postMessageToIframe('iframe-maps-list', prop, 'maps-detail' );
    }

    public postMessageToIframePopup() {
        setTimeout(() => {
            const { prop } = this.props;
            helper.postMessageToIframe('iframe-maps-list-popup', prop, 'maps-detail' );
        }, 300);
    }

    public render() {
        const { t } = this.props;
        return (
            <div className='group text-center'>
                <Modal modalIsOpenProps={this.state.modalIsOpen} 
                    closeModal={this.closeModalMaps.bind(this)}  
                    children={
                        <div style={{width:  typeof window !== 'undefined'? (window.document.documentElement!.clientWidth * 3 / 4) : '500px' , height:  typeof window !== 'undefined'? (window.document.documentElement!.clientHeight * 3 / 4 + 30) : '500px'}}>
                        <div className='caption'>{t('MAPS VIEW')}</div>
                            <div className='content map' style={{width:  typeof window !== 'undefined'? (window.document.documentElement!.clientWidth * 3 / 4) : '500px' , height:  typeof window !== 'undefined'? (window.document.documentElement!.clientHeight * 3 / 4) : '500px'}}>
                                {/* <DetailOMaps  title='modal-maps' prop={prop} height='100%'></DetailOMaps> */}
                                <iframe style={{ border: 'none' }} onLoad={this.postMessageToIframePopup.bind(this)} id='iframe-maps-list-popup' src='/maps/maps-detail-popup.html' width='100%' height='100%' ></iframe>
                            </div>
                        </div>} width={ typeof window !== 'undefined'? (window.document.documentElement!.clientWidth * 3 / 4 ).toString() : '500px'}/>
                <div className='caption'>{t('MAPS VIEW')}</div>
                <div className='content map'>
                    {/* <DetailOMaps prop={prop} height={height} title='maps' ></DetailOMaps> */}
                    <iframe style={{ border: 'none' }} onLoad={this.postMessageToIframe.bind(this)} id='iframe-maps-list' src='/maps/maps-detail-iframe.html' width='100%' height='100%' ></iframe>
                </div>
                <a onClick={this.openModalMaps.bind(this)} className='map-link'>{t('ENLARGE_THE_MAP')}</a>
            </div>
        );
    }
}

export default translate('translations')(MapsDetailComponent);
