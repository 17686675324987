import * as React from 'react';
import { Link } from 'gatsby';
import '../css/property-grid-new.css';
import { translate } from 'react-i18next';
import * as helper from '../../../helper/helpers';

interface IPropertyGNewComponentProps {
    itemsPerRow: number;
    data: any;
    stt: any;
    i18n?: any;
    t?: any;
}

interface IPropertyGNewComponentState {
    loading: boolean;
}

class PropertyGNewComponent extends React.Component<IPropertyGNewComponentProps, IPropertyGNewComponentState> {
    constructor(props: IPropertyGNewComponentProps) {
        super(props);
        this.state = {
            loading: false
        };
    }

    public render() {
        const { t } = this.props;
        return <div>
            <div className='property-image'>
                {this.props.data.Marquee !== 3 && this.props.data.Marquee !== 4 &&
                    <Link to={'/property/' + this.props.data.ID} aria-label={'property-' + this.props.data.ID}>
                        <img className='coverImage' src={`${this.props.data.ImageUrl}?w=320&h=199&fit=crop&auto=format,compress`} alt={`property-${this.props.data.ID}`} />
                    </Link>}
                {(this.props.data.Marquee === 3 || this.props.data.Marquee === 4) &&
                    <div style={{ width: '100%', height: '100%' }}>
                        <img className='coverImage' src={`${this.props.data.ImageUrl}?w=320&h=199&fit=crop&auto=format,compress`} alt={`property-${this.props.data.ID}`} />
                    </div>}
            </div>
            <div className='item-content'>
                <div className='item_introtext'>
                    <h4>{this.props.data.City}</h4>
                    <div className='desc property-new' style={{ height: '65px' }}>{this.props.data.DescriptionA.substring(0, 70)}...</div>
                    <h3>{this.props.data.Marquee !== 3 && this.props.data.Marquee !== 4 && helper.formatCurrency(this.props.data.Price)}
                        {!(this.props.data.Marquee !== 3 && this.props.data.Marquee !== 4) && <div className='prop-price'>{t(`MARQUEE_${this.props.data.Marquee}`)}</div>}</h3>
                </div>
                {this.props.data.Marquee !== 3 && this.props.data.Marquee !== 4 && <Link to={'/property/' + this.props.data.ID} className='btn' aria-label={'property-' + this.props.data.ID}>{t('More Info')}</Link>}
            </div>
        </div>;
    }
}

export default translate('translations')(PropertyGNewComponent);
