import * as React from 'react';
import { translate } from 'react-i18next';
import HtmlFormat from './general-detail/htmlFormat';
// import * as jQuery from 'jquery';

interface IDevisionsProps {
    i18n?: any;
    t?: any;
    prop: any;
}

class MenuComponent extends React.Component<IDevisionsProps> {
    constructor(props: IDevisionsProps) {
      super(props);
    }

    public componentDidMount() {
        // if(!jQuery('.divisions.group .row-fluid').text().trim()) {
        //     jQuery('.divisions.group').remove();
        // }
    }

    public render() {
        const { prop } = this.props;
        const total_div = prop.Divisions.length;
        const divs = prop.Divisions;
        if(total_div > 0) {
            for(let i = 0; i < Math.ceil(total_div/2); i++) {
                if(divs[i].Surface !== 0) {
                    if(divs[i].RoomTypeName === '') {
                        divs[i].RoomTypeName = '-----';
                    }
                    return(
                        <HtmlFormat title={divs[i].RoomTypeName} value={divs[i]} numberHtml={true} addText='m&sup2;' />
                    );
                }
            }
        }

        if(total_div > Math.ceil(total_div/2) ) {
            for (let i = Math.ceil(total_div/2); i < total_div; i++) { 
                if(divs[i].Surface !== 0) {
                    if(divs[i].RoomTypeName === '') {
                        divs[i].RoomTypeName = '-----';
                    }
                    return(
                        <HtmlFormat title={divs[i].RoomTypeName} value={divs[i]} numberHtml={true} addText='m&sup2;' />
                    );				
                }
            }
        }
        return null;
    }
}

export default translate('translations')(MenuComponent);
