import * as React from 'react';
import { Link } from 'gatsby';
import '../css/property-list.css';
import { translate } from 'react-i18next';
import * as helper from '../../../helper/helpers';
import * as FontAwesomeIcon from 'react-fontawesome';

interface IPropertyComponentProps {
    data: any;
    i18n?: any;
    t?: any;
}

interface IPropertyComponentStates {
    itemsPerRows: number;
}

class PropertyComponent extends React.Component<IPropertyComponentProps, IPropertyComponentStates> {

    constructor(props: IPropertyComponentProps) {
        super(props);
        this.state = {
            itemsPerRows: 4
        };
    }

    public render() {
        const { t, i18n } = this.props;
        return <div className='row-fluid'>
            <div className='span12 property'>
                <div className='property-image pic fixed'>
                    {this.props.data.Marquee !== 3 && this.props.data.Marquee !== 4 &&
                        <Link to={'/property/' + this.props.data.ID} aria-label={'property-' + this.props.data.ID}>
                            <img src={`${this.props.data.ImageUrl}?w=400&h=250&fit=crop&auto=format,compress`} alt={`property-${this.props.data.ID}`} />
                            {this.props.data.Marquee !== 0 && <div className='marquee' style={{ backgroundImage: `url(${helper.getMarqueeImage(this.props.data.Marquee, i18n.language)})` }}></div>}
                        </Link>}
                    {(this.props.data.Marquee === 3 || this.props.data.Marquee === 4) &&
                        <div style={{ width: '100%', height: '100%' }}>
                            <img src={`${this.props.data.ImageUrl}?w=400&h=250&fit=crop&auto=format,compress`} alt={`property-${this.props.data.ID}`} />
                            <div className='marquee' style={{ backgroundImage: `url(${helper.getMarqueeImage(this.props.data.Marquee, i18n.language)})` }}></div>
                        </div>}
                </div>
                <div className='property-info'>
                    <div className='prop-type pull-left'>{this.props.data.MainTypeName}</div>
                    <div className='prop-price pull-right'>
                        {this.props.data.Marquee !== 3 && this.props.data.Marquee !== 4 && helper.formatCurrency(this.props.data.Price)}
                        {!(this.props.data.Marquee !== 3 && this.props.data.Marquee !== 4) && <div className='prop-price'>{t(`MARQUEE_${this.props.data.Marquee}`)}</div>}
                    </div>
                    <div className='clearfix'></div>
                    <div className='prop-city'>
                        {this.props.data.City}
                    </div>
                    <div className='icons'>
                        <div className={'fleft ' + (this.props.data.SurfaceTotal === 0 ? 'hidden' : '')} >
                            <FontAwesomeIcon name='home' /> {this.props.data.SurfaceTotal} m&sup2;
                        </div>
                        <div className={'fright ' + (this.props.data.NumberOfGarages === 0 ? 'hidden' : '')}>
                            <FontAwesomeIcon name='car' /> {this.props.data.NumberOfGarages}
                        </div>
                        <div className={'fright ' + (this.props.data.NumberOfBathRooms === 0 ? 'hidden' : '')} >
                            <FontAwesomeIcon name='bath' /> {this.props.data.NumberOfBathRooms}
                        </div>
                        <div className={'fright ' + (this.props.data.NumberOfBedRooms === 0 ? 'hidden' : '')} >
                            <FontAwesomeIcon name='bed' /> {this.props.data.NumberOfBedRooms}
                        </div>
                        <div className='clear'></div>
                    </div>
                    <div className='desc visible-desktop visible-tablet'>{this.props.data.DescriptionA.substring(0, 450)}...</div>
                </div>
                <div className='clearfix'></div>
            </div>
        </div>;
    }
}

export default translate('translations')(PropertyComponent);
