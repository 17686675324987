import * as React from 'react';
import { translate } from 'react-i18next';
import * as helper from '../../helper/helpers';
import LazyLoad from 'react-lazyload';

interface IModuleProps {
    i18n: any;
    t: any; // translate
    dataContext: any;
    module: boolean;
    widthModule?: string;
    language?: string;
}

class BlogModuleTemplate extends React.Component<IModuleProps> {
    constructor(props: IModuleProps) {
        super(props);
        this.state = {
        };
    }

    public render() {
        const { i18n, widthModule, dataContext, language, module } = this.props;
        helper.setLanguageDefault(i18n.language);
        if (typeof dataContext.class !== undefined && dataContext.class !== null && dataContext.class.includes('none-lazyload')) {
            return <div className={`moduletable ${module ? '' : widthModule}`}>
                <div className={`${dataContext.class !== null ? dataContext.class : 'page-item page-item__services'}`}>
                    {dataContext.data.titlePage.public &&
                        <div className='item_header'>
                            <h2>{helper.getContentWithLanguage(dataContext.data.titlePage, language ? language : i18n.language)}</h2>
                        </div>}
                    <div className='item_fulltext' style={{ whiteSpace: 'pre-wrap', marginBottom: '35px' }} dangerouslySetInnerHTML={{ __html: helper.getContentWithLanguage(dataContext.data.body, language ? language : i18n.language) }}>
                    </div>
                    <div className='clearfix'></div>
                </div>
            </div>;
        } else {
            return <LazyLoad height={400} offset={100}>
                <div className={`moduletable ${module ? '' : widthModule}`}>
                    <div className={`${dataContext.class !== null ? dataContext.class : 'page-item page-item__services'}`}>
                        {dataContext.data.titlePage.public &&
                            <div className='item_header'>
                                <h2>{helper.getContentWithLanguage(dataContext.data.titlePage, language ? language : i18n.language)}</h2>
                            </div>}
                        <div className='item_fulltext' style={{ whiteSpace: 'pre-wrap', marginBottom: '35px' }} dangerouslySetInnerHTML={{ __html: helper.getContentWithLanguage(dataContext.data.body, language ? language : i18n.language) }}>
                        </div>
                        <div className='clearfix'></div>
                    </div>
                </div>
            </LazyLoad>;
        }
    }
}

export default translate('translations')(BlogModuleTemplate);
