
import * as React from 'react';
import ListProperty from '../PropertyComponent/list/property-list';
import GridProperty from '../PropertyComponent/grid/property-grid';
import Pagination from 'react-js-pagination';
import FeatureButton from './button-feature-fixed/button-feature';
// import OMaps from '../MapsComponent/list-maps/omnicasa-maps';
import './css/common-list-grid.css';
import * as Fuse from 'fuse.js';
import { translate } from 'react-i18next';
import * as qs from 'query-string';
import { navigate } from 'gatsby';
import * as helper from '../../helper/helpers';
import * as FontAwesomeIcon from 'react-fontawesome';

interface IPropertyListPreComponentProps {
    limitPageProps: number;
    itemsPerRowProps: number;
    i18n?: any;
    t?: any;
    propertiesListProps: any[];
    webIDsProps: any[];
    rangePricesProps: any[];
    citysProps: any[];
    page?: string;
    language: string;
}

interface IPropertyListPreComponentState {
    // option
    limitPage: number;
    itemsPerRow: number;
    MapIsOpen: boolean;
    listPageClass: string;
    gridPageClass: string;
    searchValue: string;
    activePage: number;
    selectedWebID: string;
    selectedRangePrice: string;
    selectedCity: string;
    useGoogleMaps: boolean;
    queryString: string;
    firstQueryString: boolean;
    gridPage: boolean;
    listPage: boolean;

    // data
    propsList: any[];
    renderedProperties: any[];
    webIDs: any[];
    rangePrices: any[];
    citys: any[];
    searchText: string;
    search: any[];
}

class PropertyListPreComponent extends React.Component<IPropertyListPreComponentProps, IPropertyListPreComponentState> {
    constructor(props: IPropertyListPreComponentProps) {
        super(props);
        const { limitPageProps, itemsPerRowProps } = this.props;
        this.state = {
            limitPage: limitPageProps,
            itemsPerRow: itemsPerRowProps,
            MapIsOpen: false,
            listPageClass: '',
            gridPageClass: '',
            gridPage: true,
            listPage: false,
            searchValue: '',
            propsList: [],
            renderedProperties: [],
            activePage: 1,
            selectedWebID: '',
            selectedRangePrice: '',
            selectedCity: '',
            webIDs: [],
            rangePrices: [],
            citys: [],
            searchText: '',
            search: [],
            useGoogleMaps: false,
            queryString: '',
            firstQueryString: false
        };
        this.handlePageChange = this.handlePageChange.bind(this);
    }

    public componentDidMount() {
        window.addEventListener('message', this.receiveMessage.bind(this), false);
        const { propertiesListProps } = this.props;
        const search: any[] = [];
        propertiesListProps.forEach((node: any) => {
            search.push({
                ID: node.ID,
                Price: node.Price,
                City: node.City,
                MainTypeName: node.MainTypeName
            });
        });

        const Props: any[] = [];
        propertiesListProps.forEach((prop: any) => {
            Props.push(prop);
        });

        this.setState({ search: search, propsList: Props, renderedProperties: propertiesListProps.slice(0, this.state.limitPage) });

        if (helper.findParamQueryString('page') === '') {
            this.setQueryString('page', 1);
        }
        this.prepareDataFromQueryString();
    }

    public componentWillUnmount() {
        window.removeEventListener('message', (e) => { console.log('remove listener'); }, false);
    }

    public componentWillReceiveProps(nextProps: any) {
        const { propertiesListProps } = nextProps;
        if (nextProps.language !== this.props.language) {
            const search: any[] = [];
            propertiesListProps.forEach((node: any) => {
                search.push({
                    ID: node.ID,
                    Price: node.Price,
                    City: node.City,
                    MainTypeName: node.MainTypeName
                });
            });

            const Props: any[] = [];
            propertiesListProps.forEach((prop: any) => {
                Props.push(prop);
            });
            this.setState({ search: search, propsList: Props, renderedProperties: propertiesListProps.slice(0, this.state.limitPage) });
        }

        // this.postMessageToIframe();

        if (helper.findParamQueryString('page') === '') {
            this.setQueryString('page', 1);
        }

        setTimeout(() => {
            this.prepareDataFromQueryString();
        }, 30);
    }

    public postMessageToIframe() {
        const { t } = this.props;
        helper.postMessageToIframe('iframe-maps-list', this.state.renderedProperties, t('MORE_INFO'));
    }

    public bindEvent(element: any, eventName: any, eventHandler: any) {
        if (element.addEventListener) {
            element.addEventListener(eventName, eventHandler, false);
        } else if (element.attachEvent) {
            element.attachEvent('on' + eventName, eventHandler);
        }
    }

    public handlePageChange(number: number) {
        if (this.state.MapIsOpen === true) {
            this.setState({ MapIsOpen: !this.state.MapIsOpen });
        }
        this.setQueryString('page', number);
        // Scroll.animateScroll.scrollToTop({smooth: true});
    }

    public handleClick(event: any) {
        event.preventDefault();
    }

    public filterPropList(citySelect: any, price: any, type: any, searchText: string, page: number, view: string) {
        if (view === '') {
            view = 'grid';
        }
        let gridPageClass = 'property-grid items_' + this.state.itemsPerRow;
        let listPageClass = 'property-list items_4';
        let gridPage = false;
        let listPage = false;
        if (view === 'grid') { listPageClass = listPageClass + ' hidden'; gridPage = true; }
        if (view === 'list') { gridPageClass = gridPageClass + ' hidden'; listPage = true; }

        const { propertiesListProps } = this.props;
        let updatedList = propertiesListProps;

        if (citySelect !== null && citySelect !== '') {
            updatedList = updatedList.filter((item: any) => {
                return item.City.toLowerCase() === citySelect.toLowerCase();
            });
        }

        if (price !== null && price !== '') {
            const priceProp = price.split('-');
            const minPrice = parseFloat(priceProp[0]);
            const maxPrice = parseFloat(priceProp[1]);
            if (maxPrice === 0) {
                updatedList = updatedList.filter((item: any) => {
                    return (item.Price >= minPrice && item.Marquee !== 3 && item.Marquee !== 4);
                });
            } else {
                updatedList = updatedList.filter((item: any) => {
                    return (item.Price >= minPrice && item.Price <= maxPrice && item.Marquee !== 3 && item.Marquee !== 4);
                });
            }
        }

        if (type !== null && type !== '') {
            updatedList = updatedList.filter((item: any) => item.WebID === parseInt(type, 10));
        }

        if (searchText !== '' && searchText !== null) {
            const options = { keys: ['ID', 'MainTypeName', 'City', 'Price'] };
            const fuse = new Fuse(updatedList, options);
            updatedList = fuse.search(searchText);
        }

        this.setState({
            listPageClass,
            gridPageClass,
            gridPage,
            listPage,
            activePage: page,
            selectedWebID: type,
            selectedRangePrice: price,
            selectedCity: citySelect,
            propsList: updatedList,
            renderedProperties: updatedList.slice((page - 1) * this.state.limitPage, (page - 1) * this.state.limitPage + this.state.limitPage)
        });

        this.postMessageToIframe();

        let sessionList = updatedList.filter((item: any) => item.Marquee !== 3 && item.Marquee !== 4);
        sessionList = sessionList.map(item => item.ID);
        helper.setSessionStorage(`propList${updatedList.length > 0 ? updatedList[0].Goal : ''}`, sessionList);
    }

    public async handleSearchText(event: any) {
        event.preventDefault();
        const searchText = event.target.value;
        await this.setState({ searchText });
        await this.setQueryString('search', this.state.searchText);
        this.setQueryString('page', 1);
    }

    public handleTemplatePage(view: string) {
        if (view === '') {
            view = 'grid';
        }
        let gridPageClass = 'property-grid items_' + this.state.itemsPerRow;
        let listPageClass = 'property-list items_4';
        let gridPage = false;
        let listPage = false;
        if (view === 'grid') { listPageClass = listPageClass + ' hidden'; gridPage = true; }
        if (view === 'list') { gridPageClass = gridPageClass + ' hidden'; listPage = true; }
        this.setState({ listPageClass, gridPageClass, gridPage, listPage });
    }

    public handleChangeToList() {
        this.setQueryString('view', 'list');
    }

    public handleChangeToGrid() {
        this.setQueryString('view', 'grid');
    }

    public async handleOpenMaps() {
        this.postMessageToIframe();
        this.setState({ MapIsOpen: !this.state.MapIsOpen });
    }

    public setQueryString(name: string, object: any) {
        const parsed = qs.parse(location.search);
        let queryString = '';
        if (object === null || object === '' || typeof object === 'undefined' || object === 'none') {
            parsed[name] = undefined;
        } else {
            switch (name) {
                case 'view':
                    parsed['view'] = object;
                    break;
                case 'type':
                    parsed['type'] = object;
                    break;
                case 'price':
                    parsed['price'] = object;
                    break;
                case 'city':
                    parsed['city'] = object;
                    break;
                case 'search':
                    parsed['search'] = object;
                    break;
                case 'page':
                    parsed['page'] = object;
                    break;
                default: break;
            }
        }
        const page = helper.findParamQueryString('page');
        if (page.toString() !== '1' && name !== 'page' && name !== 'view' && name !== 'nobody') {
            parsed['page'] = 1;
        }
        queryString = '?' + qs.stringify(parsed);
        helper.setSessionStorage('URL', typeof window !== 'undefined' ? window.location.pathname : '/');
        navigate(queryString);
    }

    public prepareDataFromQueryString() {
        let selectedOptionCity = helper.findParamQueryString('city');
        let selectedOptionPrice = helper.findParamQueryString('price');
        let selectedOptionType = helper.findParamQueryString('type');
        let searchText = helper.findParamQueryString('search');
        let page = helper.findParamQueryString('page');
        let view = helper.findParamQueryString('view');

        if (typeof selectedOptionCity === 'undefined') {
            selectedOptionCity = null;
        }

        if (typeof selectedOptionPrice === 'undefined') {
            selectedOptionPrice = null;
        }

        if (typeof selectedOptionType === 'undefined') {
            selectedOptionType = null;
        }

        if (typeof searchText === 'undefined') {
            searchText = '';
        }

        if (typeof page === 'undefined') {
            page = 1;
        }

        if (typeof view === 'undefined') {
            view = 'grid';
        }

        this.filterPropList(selectedOptionCity, selectedOptionPrice, selectedOptionType, searchText, parseInt(page, 10), view);
    }

    public handleGotoDemandRegister() {
        helper.setSessionStorage('URL', typeof window !== 'undefined' ? window.location.pathname : '/');
        navigate('/demand-register');
    }

    public handleUserInput(e: any) {
        const name = e.target.id;
        const value = e.target.value;
        this.setQueryString(name, value);
    }

    public receiveMessage(e: any) {
        if (typeof e.data === 'object') {
            if (typeof e.data.type !== 'undefined') {
                // const data = JSON.parse(e.data);
                if (e.data.type === 'maps') {
                    navigate(`/property/${e.data.mess}`);
                }
            }
        }
    }

    public render() {
        const { t, rangePricesProps, citysProps, webIDsProps } = this.props;
        return <div id='PropertyListRegion' ref='PropertyListRegion'>
            <FeatureButton propsList={this.state.renderedProperties} />
            <div className='row-fluid property-list-header'>
                <div className='span8 links'>
                    <div className='span3 link sort-wrapper' style={{ zIndex: 2 }}>
                        <select
                            style={{ width: '100%' }}
                            id='city'
                            placeholder={t('City')}
                            defaultValue='none'
                            onChange={this.handleUserInput.bind(this)}>
                            <option value='none'>{t('City')}</option>
                            {citysProps.map((item, i) => {
                                return <option key={i} value={item.Name}>{`${item.Name} [${item.Zip}]`}</option>;
                            })}
                        </select>
                    </div>
                    <div className='span3 link' style={{ zIndex: 2 }}>
                        <select
                            style={{ width: '100%' }}
                            id='price'
                            defaultValue='none'
                            onChange={this.handleUserInput.bind(this)}>
                            <option value='none'>{t('Price')}</option>
                            {rangePricesProps.map((item, i) => {
                                return <option key={i} value={`${item.Min}-${item.Max}`}>{item.Name}</option>;
                            })}
                        </select>
                    </div>
                    <div className='span3 link' style={{ zIndex: 2 }}>
                        <select
                            style={{ width: '100%' }}
                            id='type'
                            defaultValue='none'
                            onChange={this.handleUserInput.bind(this)}>
                            <option value='none'>{t('Type')}</option>
                            {webIDsProps.map((item, i) => {
                                return <option key={i} value={item.ID}>{item.Name}</option>;
                            })}
                        </select>
                    </div>
                    <div className='span3 link'>
                        <input type='text'
                            style={{ fontSize: '16px', height: '35px', width: '100%' }}
                            value={this.state.searchText}
                            onChange={this.handleSearchText.bind(this)}
                            placeholder={t('Search')}
                            aria-label='Search Input'
                        />
                    </div>
                </div>
                <div className='span4 form-wrapper'>
                    <button
                        className='btn'
                        type='button'
                        onClick={this.handleOpenMaps.bind(this)} aria-label='Open Maps'> <FontAwesomeIcon name='map' /> </button>
                    <button
                        className='btn'
                        type='button'
                        onClick={this.handleChangeToGrid.bind(this)} aria-label='Change To Grid' > <FontAwesomeIcon name='th' /> </button>
                    <button className='btn'
                        type='button'
                        onClick={this.handleChangeToList.bind(this)} aria-label='Change To List'> <FontAwesomeIcon name='bars' /> </button>
                    <button className='btn'
                        type='button'
                        onClick={this.handleGotoDemandRegister.bind(this)} aria-label='Go to demand register' > <FontAwesomeIcon name='pencil' /> </button>
                </div>
            </div>
            {/* {this.state.MapIsOpen && <OMaps propsList={this.state.renderedProperties} height='500px' ></OMaps>} */}
            <div className={`${!this.state.MapIsOpen ? 'hidden' : ''}`}>
                <iframe style={{ border: 'none' }} onLoad={this.postMessageToIframe.bind(this)} id='iframe-maps-list' src='/maps/maps-list-iframe.html' width='100%' height='500px' ></iframe>
            </div>
            <div className='clear'></div>
            {this.state.gridPage && <div className={this.state.gridPageClass}>
                <GridProperty ListProperty={this.state.renderedProperties}
                    itemsPerRow={this.state.itemsPerRow} />
            </div>}
            {this.state.listPage && <div className={this.state.listPageClass}>
                <ListProperty ListProperty={this.state.renderedProperties} />
            </div>}
            <div className='clear'></div>
            {this.state.renderedProperties.length !== 0 && <div className='pagination'>
                <Pagination
                    activePage={this.state.activePage}
                    itemsCountPerPage={this.state.limitPage}
                    totalItemsCount={this.state.propsList.length}
                    pageRangeDisplayed={6}
                    onChange={this.handlePageChange}
                />
            </div>}
            <div className='title' style={{ textAlign: 'center', marginBottom: '20px' }}>
                {this.state.renderedProperties.length !== 0 && <span >Page: {this.state.activePage}/{Math.ceil(this.state.propsList.length / this.state.limitPage)} [{this.state.propsList.length}] </span>}
            </div>
        </div>;
    }
}

export default translate('translations')(PropertyListPreComponent);
